/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import { useParams, Link } from 'react-router-dom';
import Header from '../../components/header/header';
import StaticMap from '../../components/azurePlacesWithMap/staticMap';
import { CreateAuthRequest, fetchData } from '../../API/constants';
import ModalConfirm from '../../components/ModalConfirm/modalConfirm';
import './ministries.scss';

const acceptedFiles = ['jpg', 'png', 'jpeg', 'svg', 'gif'];

const MinistryDetails = ({ NotificationManager }) => {
  const [ministryInfo, setMinistryInfo] = React.useState({});
  const { idMinistry } = useParams();
  const [showModal, setShowModal] = React.useState(false);

  const getMinistryInfo = React.useCallback(async () => {
    if (idMinistry) {
      const request = await CreateAuthRequest('GET', null, true);
      fetchData(`ministries/${idMinistry}`, request)
        .then((response) => {
          setMinistryInfo(response);
        });
    }
  }, [idMinistry]);

  React.useEffect(() => { getMinistryInfo(); }, [getMinistryInfo]);

  const onImageChange = async (e) => {
    const { files } = e.target;
    const newFile = files[0];
    if (newFile) {
      if (acceptedFiles.includes(newFile.name.split('.').pop().toLowerCase())) {
        const request = await CreateAuthRequest('POST', null, false);
        const body = new FormData();
        body.append('image', newFile);
        request.body = body;
        fetchData(`ministries/${idMinistry}/image`, request)
          .then((response) => {
            setMinistryInfo(response);
          });
        document.getElementById('ministry-picture').value = '';
      } else {
        NotificationManager.warning('This type of file is not allowed.', 'Warning!', 5000);
      }
    }
  };

  const handleShowModal = () => {
    setShowModal(!showModal);
  };

  const deleteMinistry = async () => {
    const request = await CreateAuthRequest('PUT', { disabled: !ministryInfo.disabled }, true);
    fetchData(`ministries/${idMinistry}`, request)
      .then(() => {
        setMinistryInfo({ ...ministryInfo, disabled: !ministryInfo.disabled });
        NotificationManager.success('Ministry updated successfully.', 'Success!', 5000);
        handleShowModal();
        // history.push('/pannel/wikis');
      });
  };

  return (
    <div className="nkdx">
      <Header
        title="Ministry details"
        right={() => (
          <>
            <buton type="button" style={{ marginRight: '5px' }} className={`btn btn-${ministryInfo.disabled ? 'success' : 'danger'} btn-radius`} onClick={handleShowModal}>{ministryInfo.disabled ? 'Activate' : 'Deactivate'}</buton>
            <Link to={`/pannel/ministries/edit/${idMinistry}`} className="btn btn-primary btn-radius">Edit Ministry</Link>
          </>
        )}
      />
      <div className="card mg-top">
        <div className="card-body">
          <div className="row">
            <div className="col-md-4 col-12">
              <div className="form-group">
                <label>Image</label>
                <div
                  className="img-ministry"
                  style={{ backgroundImage: ministryInfo.image_url && `url(${process.env.REACT_APP_API_URL}${ministryInfo.image_url.slice(1, ministryInfo.image_url.length)})` }}
                />
                <label className="mg-top btn btn-primary btn-radius">
                  Change Image
                  <input id="ministry-picture" className="hide" type="file" onChange={onImageChange} />
                </label>
              </div>
            </div>
            <div className="col-md-8">
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>Name</label>
                    <p>{ministryInfo.name}</p>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label>Contact person</label>
                    <p>{ministryInfo.contact_person || 'Not assigned'}</p>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label>Phone number</label>
                    <p>{ministryInfo.phone_number || 'Not assigned'}</p>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label>Email</label>
                    <p>{ministryInfo.email || 'Not assigned'}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 col-12">
              <div className="form-group">
                <label>Description</label>
                <p>{ministryInfo.description || 'Not assigned'}</p>
              </div>
            </div>
            <div className="col-md-12 col-12">
              <div className="form-group">
                <label>Location</label>
                {
                  ministryInfo.address
                    ? (
                      <>
                        <p>{ministryInfo.address}</p>
                        <StaticMap
                          zoom={16}
                          initialPos={[ministryInfo.latitude, ministryInfo.longitude]}
                          positionProp={[ministryInfo.latitude, ministryInfo.longitude]}
                        />
                      </>
                    )
                    : <p>Not available</p>
                }
              </div>

            </div>
          </div>
        </div>
      </div>

      <ModalConfirm
        show={showModal}
        message={ministryInfo.name}
        type={ministryInfo.disabled ? 'activate' : 'deactivate'}
        onHide={handleShowModal}
        onConfirm={deleteMinistry}
      />
    </div>
  );
};

MinistryDetails.propTypes = {
  NotificationManager: PropTypes.object,
};

MinistryDetails.defaultProps = {
  NotificationManager: null,
};

export default MinistryDetails;
