/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-lonely-if */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable arrow-parens */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useHistory, useParams, useLocation } from 'react-router-dom';
// import draftToMarkdown from 'draftjs-to-markdown';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import DateTime from 'react-datetime';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import AzurePlacesWithMap from '../../components/azurePlacesWithMap/azurePlacesWithMap';
import Header from '../../components/header/header';
import SelectableList from '../../components/SelectableList/selectableList';
import { fetchData, CreateAuthRequest } from '../../API/constants';
import TypeaheadWithOptions from '../../components/Typeahead/typeahead';

const maxDescriptionLength = 65000;
const appState = {
  NEWTASK: 'NEWTASK',
  EDITINGTASK: 'EDITINGTASK',
  NEWSUBTASK: 'NEWSUBTASK',
  EDITINGSUBTASK: 'EDITINGSUBTASK',
};

const NewTask = (props) => {
  const { NotificationManager, showLoader } = props;
  const { id: idTask, idSub } = useParams();
  const location = useLocation();
  const history = useHistory();
  const [infoTask, setInfoTask] = React.useState({ section: { name: '' } });
  const [form, setForm] = React.useState({ editorMKDN: EditorState.createEmpty() });
  const [validated, setValidated] = React.useState(false);
  function getappstate() {
    let st = appState.NEWTASK;
    if (idTask) {
      if (idSub) {
        st = appState.EDITINGSUBTASK;
      } else if (location.pathname.includes('edit')) {
        st = appState.EDITINGTASK;
      } else {
        st = appState.NEWSUBTASK;
      }
    } else {
      st = appState.NEWTASK;
    }
    return st;
  }
  const stateApp = getappstate();

  const getTask = React.useCallback(async () => {
    showLoader(true);
    const request = await CreateAuthRequest('GET', null, true);

    fetchData(`tasks/${idTask}`, request)
      .then(response => {
        const markdownString = response.description;
        const rawData = markdownToDraft(markdownString);
        const contentState = convertFromRaw(rawData);
        const newForm = {
          id_user_supervisor: response.id_user_supervisor,
          title: response.title,
          // description: response.description,
          deadline_date: moment(response.deadline_date).tz('Atlantic/Bermuda'),
          priority: response.priority,
          difficulty: response.difficulty,
          // disabled: response.disabled,
          location: response.location,
          // users: response.users,
          id_section: response.id_section,
        };
        setInfoTask(response);
        const obj = { id_section: response.id_section };
        if (response.deadline_date) {
          obj.deadline_date = response.deadline_date;
        }
        if (response.location) {
          obj.location = response.location;
        }
        setForm({ ...form, ...obj });
        if (stateApp === appState.EDITINGTASK) {
          setForm({ editorMKDN: EditorState.createWithContent(contentState), ...newForm });
        }
      })
      .catch()
      .finally(() => showLoader(false));
  }, [idTask]);

  React.useEffect(() => {
    if (stateApp !== appState.NEWTASK) {
      getTask();
    }
  }, [getTask]);

  const getSubtaskInfo = React.useCallback(async () => {
    showLoader(true);
    const request = await CreateAuthRequest('GET', null, true);

    fetchData(`tasks/${idSub}`, request)
      .then(response => {
        const markdownString = response.description;
        const rawData = markdownToDraft(markdownString);
        const contentState = convertFromRaw(rawData);
        const newForm = {
          id_user_supervisor: response.id_user_supervisor,
          title: response.title,
          // description: response.description,
          deadline_date: moment(response.deadline_date).tz('Atlantic/Bermuda'),
          priority: response.priority,
          difficulty: response.difficulty,
          // disabled: response.disabled,
          location: response.location,
          // users: response.users,
          id_section: response.id_section,
        };
        // setInfoTask(response);
        setForm({ editorMKDN: EditorState.createWithContent(contentState), ...newForm });
      })
      .catch()
      .finally(() => showLoader(false));
  }, [idSub]);

  React.useEffect(() => {
    if (stateApp === appState.EDITINGSUBTASK) {
      getSubtaskInfo();
    }
  }, [idSub]);

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setValidated(true);
    if (e.target.checkValidity()) {
      if (form.priority && form.difficulty && form.title && form.id_section) {
        showLoader(true);
        let method = 'POST';
        let url = 'tasks';
        let goUrl = '';
        let successMesage = '';
        const formTosend = { ...form, location: null };
        const rawContentState = convertToRaw(form.editorMKDN.getCurrentContent());
        const description = draftToMarkdown(rawContentState);
        formTosend.description = description;
        delete formTosend.editorMKDN;
        // if (form.deadline_date) {
        //   formTosend.deadline_date = moment(form.deadline_date);
        // }
        if (!formTosend.id_user_supervisor > 0) {
          delete formTosend.id_user_supervisor;
        }
        if (form.location) {
          if (typeof form.location.address === 'string') {
            formTosend.location = form.location;
            delete formTosend.location.id_location;
          } else {
            formTosend.location = {
              latitude: form.location.position.lat,
              longitude: form.location.position.lon,
            };
            if (form.location.poi) {
              formTosend.location.address = form.location.poi.name;
            } else {
              formTosend.location.address = form.location.address.freeformAddress;
            }
          }
        } else {
          delete formTosend.location;
        }
        switch (stateApp) {
          case appState.NEWTASK:
            if (formTosend.users) {
              if (formTosend.users.length === 0) {
                delete formTosend.users;
              }
            }
            method = 'POST';
            successMesage = 'Task has been created.';
            goUrl = '/pannel/tasks/detail/';
            if (description === '') {
              delete formTosend.description;
            }
            break;
          case appState.EDITINGTASK:
            goUrl = '/pannel/tasks/detail/';
            delete formTosend.id_section;
            // if (formTosend.id_status === infoTask.id_status) {
            //   delete formTosend.id_status;
            // }
            method = 'PUT';
            successMesage = 'Task has been updated.';
            url = `${url}/${idTask}`;
            break;
          case appState.NEWSUBTASK:
            method = 'POST';
            successMesage = 'Subtask has been created.';
            goUrl = `/pannel/tasks/detail/${infoTask.id_task}/subtask/`;
            if (formTosend.users) {
              if (formTosend.users.length === 0) {
                delete formTosend.users;
              }
            }
            formTosend.id_task_parent = idTask;
            delete formTosend.id_section;
            if (description === '') {
              delete formTosend.description;
            }
            break;
          case appState.EDITINGSUBTASK:
            goUrl = `/pannel/tasks/detail/${infoTask.id_task}/subtask/`;
            url = `${url}/${idSub}`;
            delete formTosend.id_section;
            method = 'PUT';
            successMesage = 'Subtask has been updated.';
            // if (formTosend.id_status === infoTask.id_status) {
            //   delete formTosend.id_status;
            // }
            break;
          default:
            break;
        }

        const requestWithToken = await CreateAuthRequest('GET', null, true);
        let users = [];
        setForm({ ...form, users });
        if (stateApp === appState.EDITINGTASK || stateApp === appState.EDITINGSUBTASK) {
          if (formTosend.users) {
            if (infoTask.users.length === 0 && formTosend.users.length === 0) {
              delete formTosend.users;
            } else {
              if (infoTask.users.length === 0) {
                // formTosend.users has users, let's add them
                requestWithToken.method = 'POST';
                requestWithToken.body = JSON.stringify({ users: formTosend.users });
                fetchData(`tasks/${idTask}/users`, requestWithToken)
                  .then(response => { setInfoTask({ ...infoTask, users: response.rows }); });
              } else {
                if (formTosend.users.length === 0) {
                  // let's erase them
                  requestWithToken.method = 'DELETE';
                  delete requestWithToken.body;
                  setInfoTask({ ...infoTask, users: [] });
                  for (let i = 0; i < infoTask.users.length; i += 1) {
                    const usr = infoTask.users[i];
                    fetchData(`tasks/${idTask}/users/${usr.id_user}`, requestWithToken);
                  }
                } else {
                  // both have users, let's compare them to choose which to delete and which to add
                  const userstoAdd = [];
                  let usersToDelete = infoTask.users.map(u => u.id_user);
                  users = infoTask.users;

                  for (let i = 0; i < formTosend.users.length; i += 1) {
                    const userId = formTosend.users[i];
                    if (usersToDelete.includes(userId)) {
                      usersToDelete = usersToDelete.filter(u => u !== userId);
                      users = users.filter(u => u.id_user !== userId);
                    } else {
                      userstoAdd.push(userId);
                    }
                  }

                  if (usersToDelete.length > 0) {
                    requestWithToken.method = 'DELETE';
                    delete requestWithToken.body;
                    for (let i = 0; i < usersToDelete.length; i += 1) {
                      const userId = usersToDelete[i];
                      fetchData(`tasks/${idTask}/users/${userId}`, requestWithToken);
                    }
                  }

                  if (userstoAdd.length > 0) {
                    requestWithToken.method = 'POST';
                    requestWithToken.body = JSON.stringify({ users: userstoAdd });
                    fetchData(`tasks/${idTask}/users`, requestWithToken)
                      .then(response => { setInfoTask({ ...infoTask, users: response.rows }); });
                  }
                }
              }
            }
            delete formTosend.users;
          }
        }
        // showLoader(false);
        const request = await CreateAuthRequest(method, formTosend, true);
        fetchData(url, request)
          .then(response => {
            NotificationManager.success(successMesage, '¡Success!', 5000);
            if (goUrl.length > 0) {
              history.replace(`${goUrl}${response.id_task}`);
            } else {
              history.replace(`${goUrl}${response.id_task}`);
              // if (stateApp === appState.EDITINGTASK) {
              //   getTask();
              // }
              // if (stateApp === appState.EDITINGSUBTASK) {
              //   getSubtaskInfo();
              // }
            }
          })
          .catch((response) => {
            response.then((r) => {
              NotificationManager.error(`An error has occurred. ${r.message}`, '¡Error!', 5000);
            });
          })
          .finally(() => showLoader(false));
      } else if (!form.id_section) {
        NotificationManager.warning('Sectionis required.', '¡Warning!', 5000);
      } else {
        NotificationManager.warning('Some fields are required.', '¡Warning!', 5000);
      }
    }
  };

  const handleChangeFormValue = (e) => {
    const { name, value } = e.target;
    console.log(value);
    if (name === 'deadline_date') {
      const day = moment().tz('Atlantic/Bermuda');
      const date = moment(value);
      const isValid = date.isAfter(day);
      setForm({ ...form, [name]: isValid ? date : day });
    } else {
      setForm({ ...form, [name]: value });
    }
  };

  const handleSelectUser = (list) => {
    setForm({ ...form, users: list.map(elm => elm.id_user) });
  };

  const valid = (current) => {
    const day = moment().tz('Atlantic/Bermuda').add(-1, 'days');
    let isValid = current.isAfter(day);
    if ((stateApp === appState.NEWSUBTASK || stateApp === appState.EDITINGSUBTASK) && infoTask.deadline_date) {
      if (moment(infoTask.deadline_date).tz('Atlantic/Bermuda').isAfter(day)) {
        const limit = moment(infoTask.deadline_date).tz('Atlantic/Bermuda');
        isValid = current.isAfter(day) && current.isBefore(limit);
      }
    }
    return isValid;
  };

  const getTitle = () => {
    switch (stateApp) {
      case appState.NEWTASK:
        return 'Create a new task';
      case appState.EDITINGTASK:
        return 'Edit task';
      case appState.NEWSUBTASK:
        return 'Create a new subtask';
      case appState.EDITINGSUBTASK:
        return 'Edit subtask';
      default:
        return 'Create a new task';
    }
  };

  const getSubmitButtonText = () => {
    switch (stateApp) {
      case appState.NEWTASK:
        return 'Create task';
      case appState.EDITINGTASK:
        return 'Save task';
      case appState.NEWSUBTASK:
        return 'Create subtask';
      case appState.EDITINGSUBTASK:
        return 'Save subtask';
      default:
        return 'Create task';
    }
  };

  return (
    <div className="tal nkdx">
      <Header
        title={getTitle()}
        icon="fas fa-list"
      />
      <div className="card shadow">
        <div className="card-body">
          <form className={`form needs-validation ${validated ? ' was-validated' : ''}`} onSubmit={handleOnSubmit} noValidate>
            <div className="row">
              <div className="form-group col-12 ">
                <label>Task name</label>
                <input name="title" placeholder="Type task name." className="input-shadow form-control" value={form.title} onChange={handleChangeFormValue} required />
                <div className="invalid-feedback">Task name is required</div>
              </div>

              <div className="form-group col-12 ">
                <label>Description</label>
                <Editor
                  handleBeforeInput={val => {
                    const textLength = form.editorMKDN.getCurrentContent().getPlainText().length;
                    if (val && textLength >= maxDescriptionLength) {
                      return 'handled';
                    }
                    return 'not-handled';
                  }}
                  handlePastedText={val => {
                    const textLength = form.editorMKDN.getCurrentContent().getPlainText().length;
                    return ((val.length + textLength) >= maxDescriptionLength);
                  }}
                  toolbar={
                    {
                      options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'emoji', 'history'],
                      inline: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['bold', 'italic', 'underline', 'strikethrough'],

                      },
                      textAlign: { inDropdown: true },
                      link: { inDropdown: true },
                    }
                  }

                  editorState={form.editorMKDN}
                  wrapperClassName="border input-shadow"
                  onEditorStateChange={(value) => handleChangeFormValue({ target: { name: 'editorMKDN', value } })}
                />
              </div>

              <div className="form-group col-12 col-md-4 ">
                <label>Due date</label>
                <DateTime
                  className="input-shadow"
                  closeOnSelect
                  dateFormat="DD/MMM/YYYY"
                  timeFormat
                  isValidDate={valid}
                  initialValue={form.deadline_date && form.deadline_date}
                  onChange={(dateMoment) => handleChangeFormValue({ target: { name: 'deadline_date', value: moment(dateMoment) } })}
                  value={form.deadline_date && moment(form.deadline_date)}
                  inputProps={{ placeholder: 'Choose due date' }}
                  displayTimeZone="Atlantic/Bermuda"
                />
              </div>
              <div className="form-group col-12 col-md-4 ">
                <label>Priority</label>
                <select className="form-control input-shadow" name="priority" value={form.priority} onChange={handleChangeFormValue} required>
                  <option value="">Choose dificulty</option>
                  <option value="1">Low</option>
                  <option value="2">Medium</option>
                  <option value="3">High</option>
                </select>
                <div className="invalid-feedback">Priority is required</div>
              </div>
              <div className="form-group col-12 col-md-4 ">
                <label>Difficulty</label>
                <select className="form-control input-shadow" name="difficulty" value={form.difficulty} onChange={handleChangeFormValue} required>
                  <option value="">Choose difficulty</option>
                  <option value="1">Low</option>
                  <option value="2">Medium</option>
                  <option value="3">High</option>
                </select>
                <div className="invalid-feedback">Difficulty is required</div>
              </div>

              <div className="form-group col-12 col-md-4">
                <label>Section</label>
                {
                  stateApp === appState.NEWTASK
                    ? (
                      <TypeaheadWithOptions
                        // labelKey={(option) => (`${option.id} ${option.name}`)}
                        labelKey="name"
                        idLabel="id_section"
                        url="sections"
                        multiple={false}
                        minLength={3}
                        onSelect={(option) => handleChangeFormValue({ target: { name: 'id_section', value: option?.id_section } })}
                        placeholder="Type section to search..."
                        // defaultOptions={[{ id: 1, name: 'Section1' },
                        // { id: 2, name: 'Section2' }]}
                        renderOptions={(option) => (
                          <span>{`${option.id_section} ${option.name}`}</span>
                        )}
                        inputProps={{ required: 'required' }}
                      />
                    )
                    : <p>{infoTask.section.name}</p>
                }
              </div>

              <div className="form-group col-12">
                <label>Supervisor</label>
                <TypeaheadWithOptions
                  idLabel="id_user"
                  labelKey={(option) => `${option.name} ${option.last_name}`}
                  url="users"
                  filterBy="user_type=2"
                  multiple={false}
                  minLength={3}
                  onSelect={(option) => handleChangeFormValue({ target: { name: 'id_user_supervisor', value: option?.id_user } })}
                  placeholder="Type supervisor to search..."
                  renderOptions={
                    option => (
                      <div>
                        <div>{`${option.name} ${option.last_name}`}</div>
                        <span className="badge badge-secondary">{`${option.email}`}</span>
                      </div>
                    )
                  }
                  defaultSelected={infoTask.supervisor_user && (form.id_user_supervisor === infoTask.supervisor_user.id_user && infoTask.supervisor_user)}
                // inputProps={{ disabled: !form.id_section }}
                />
              </div>
              <div className="form-group col-12">
                <SelectableList
                  // url={(form.id_section > 0) ? `${process.env.REACT_APP_API_URL}sections/${form.id_section}/users` : null}
                  url={`${process.env.REACT_APP_API_URL}users`}
                  label="Add people to task team"
                  onSelectItem={handleSelectUser}
                  idKey="id_user"
                  labelKey={(option) => `${option.name} ${option.last_name}`}
                  defaultSelected={infoTask.users}
                  inputProps={{ disabled: !form.id_section }}
                />
              </div>
              <div className="form-group col-12">
                <AzurePlacesWithMap
                  name="location"
                  label="Location"
                  placeholder="type address to search..."
                  icon={faPlus}
                  value={form.location && form.location}
                  // () => {
                  //   if (form.location) {
                  //     // if (form.location.position) {
                  //     //   return form.location;
                  //     // }
                  //     // return {
                  //     //   position: [
                  //     //     parseFloat(form.location.latitude),
                  //     //     parseFloat(form.location.longitude),
                  //     //   ],
                  //     // };
                  //     return form.location;
                  //   }
                  //   return null;
                  // }}
                  handleInputChange={handleChangeFormValue}
                // TODO: objetos diferentes para editar y agregar
                />

              </div>
            </div>
            <div className="tac">
              <button type="submit" className="btn btn-primary btn-radius">{getSubmitButtonText()}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

NewTask.propTypes = {
  NotificationManager: PropTypes.object,
  showLoader: PropTypes.func,
};

NewTask.defaultProps = {
  NotificationManager: null,
  showLoader: f => f,
};

export default NewTask;
