/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { CreateAuthRequest, fetchData } from '../../API/constants';
import './users.scss';
import Header from '../../components/header/header';
import { UserContext } from '../../context/userContext/userContext';

const userType = (t) => {
  let text = 'Not found';
  switch (t) {
    case 1:
      text = "Admin";
      break;
    case 2:
      text = "Supervisor";
      break;
    case 3:
      text = "Employee";
      break;

    default:
      break;
  }
  return text;
};

const NewUser = ({ NotificationManager, showLoader }) => {
  const history = useHistory();
  const location = useLocation();
  const { user } = React.useContext(UserContext);
  const [form, setForm] = React.useState({});
  const [validated, setValidated] = React.useState(false);
  const { idUser } = useParams();
  const isEditing = !!idUser;
  const isProfile = location.pathname.includes('profile');

  const getUserInfo = React.useCallback(async () => {
    if (idUser) {
      const request = await CreateAuthRequest('GET', null, true);
      fetchData(`users/${idUser}`, request)
        .then((response) => {
          setForm({
            name: response.name,
            last_name: response.last_name,
            email: response.email,
            phone_number: response.phone_number,
            type: response.type,
          });
        });
    }
  }, [idUser]);

  React.useEffect(() => { getUserInfo(); }, [getUserInfo]);

  const handleFormChange = ({ target }) => {
    const { name, value } = target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setValidated(true);
    if (e.target.checkValidity()) {
      showLoader(true);
      if (isEditing) {
        const formToSend = isProfile ? { name: form.name, last_name: form.last_name, phone_number: form.phone_number } : { ...form };
        delete formToSend.email;
        const url = isProfile ? 'me' : `users/${idUser}`;
        const request = await CreateAuthRequest('PUT', formToSend, true);
        fetchData(url, request)
          .then(() => {
            NotificationManager.success('User has been updated.', 'Success!', 5000);
            if (isProfile) {
              history.replace(`/pannel/users/profile/${idUser}`);
            } else {
              history.replace(`/pannel/users/details/${idUser}`);
            }
          })
          .catch((response) => {
            try {
              response.then((error) => {
                NotificationManager.error(error.message, 'Error!', 5000);
              });
            } catch (error) {
              NotificationManager.error(error, 'Error!', 5000);
            }
          })
          .finally(() => {
            showLoader(false);
          });
      } else {
        const request = await CreateAuthRequest('POST', form, true);
        fetchData('users', request)
          .then((response) => {
            NotificationManager.success('User has been created.', 'Success!', 5000);
            history.replace(`/pannel/users/details/${response.id_user}`);
          })
          .catch((response) => {
            try {
              response.then((error) => {
                NotificationManager.error(error.message, 'Error!', 5000);
              });
            } catch (error) {
              NotificationManager.error(error, 'Error!', 5000);
            }
          })
          .finally(() => {
            showLoader(false);
          });
      }
    }
  };

  return (
    <div className="users-new">
      <Header title={isEditing ? 'Edit User' : 'Create new user'} />
      <div className="card">
        <div className="card-body">
          <form className={`form needs-validation ${validated ? ' was-validated' : ''}`} onSubmit={handleSubmit} noValidate>
            <div className="row">
              <div className="col-4">
                <div className="form-group">
                  <label>Name</label>
                  <input className="form-control input-shadow" placeholder="Type user name" name="name" value={form.name} onChange={handleFormChange} required />
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label>Last name</label>
                  <input className="form-control input-shadow" placeholder="Type user last_name" name="last_name" value={form.last_name} onChange={handleFormChange} required />
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label>Email</label>
                  {
                    isEditing
                      ? <p>{form.email}</p>
                      : <input type="email" className="form-control input-shadow" placeholder="Type user email" name="email" value={form.email} onChange={handleFormChange} required />
                  }
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-4">
                <div className="form-group">
                  <label>Phone number</label>
                  <input pattern="[0-9]+" className="form-control input-shadow" placeholder="Type user phone_number" name="phone_number" value={form.phone_number} onChange={handleFormChange} />
                  <div className="invalid-feedback">Please provide a valid phone number.</div>
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label>Type</label>
                  {
                    (!isProfile && user.id_user !== parseInt(idUser, 10))
                      ? (
                        <select className="form-control input-shadow" name="type" value={form.type} onChange={handleFormChange} required>
                          <option value="">Choose user type</option>
                          <option value="3">Employee</option>
                          <option value="2">Supervisor</option>
                          <option value="1">Admin</option>
                        </select>
                      )
                      : (
                        <p>{userType(user.type)}</p>
                      )
                  }

                </div>
              </div>

              {/* <div className="col-8">
              <div className="form-group">
                <label>Address</label>
                <input className="form-control" />
              </div>
            </div> */}
            </div>
            <div className="mg-top tac">
              <button type="submit" className="btn btn-primary btn-radius">{isEditing ? 'Save' : 'Create'}</button>
            </div>
          </form>
          {/* <label style={{ fontSize: '18px', fontWeight: '700' }}>License information</label>
          <div className="row">
            <div className="col-4">
              <div className="form-group">
                <label>License number</label>
                <input className="form-control" />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label>Expiration date</label>
                <input className="form-control" />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label>License picture</label>
                <input className="form-control" />
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

NewUser.propTypes = {
  NotificationManager: PropTypes.object,
  showLoader: PropTypes.func,
};

NewUser.defaultProps = {
  NotificationManager: null,
  showLoader: (f) => f,
};

export default NewUser;
