/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import { useParams, Link } from 'react-router-dom';
import { CreateAuthRequest, fetchData } from '../../API/constants';
import Header from '../../components/header/header';
import LicenseUser from './license';
import Pagination from '../../components/Pagination/pagination';
import './users.scss';
import { UserContext } from '../../context/userContext/userContext';

const acceptedFiles = ['jpg', 'png', 'jpeg', 'svg', 'gif'];

const userType = (t) => {
  let text = 'Not found';
  switch (t) {
    case 1:
      text = 'Admin';
      break;
    case 2:
      text = 'Supervisor';
      break;
    case 3:
      text = 'Employee';
      break;
    default:
      break;
  }
  return text;
};

const Profile = ({ showLoader, NotificationManager }) => {
  const { idUser } = useParams();
  const [userInfo, setUserInfo] = React.useState({});
  const [page, setPage] = React.useState(1);
  const [jobList, setJobList] = React.useState({ count: 0, rows: [] });

  const { updateUser } = React.useContext(UserContext);

  const getUserInfo = React.useCallback(async () => {
    if (idUser) {
      showLoader(true);
      const request = await CreateAuthRequest('GET', null, true);
      fetchData('me', request)
        .then((response) => {
          setUserInfo(response);
        })
        .finally(() => {
          showLoader(false);
        });
    }
  }, [idUser, showLoader, fetchData, CreateAuthRequest]);

  React.useEffect(() => { getUserInfo(); }, [getUserInfo]);

  const getUsersJobs = React.useCallback(async () => {
    if (idUser) {
      showLoader(true);
      const request = await CreateAuthRequest('GET', null, true);
      fetchData(`me/jobs?page=${page}`, request)
        .then((response) => {
          setJobList(response);
        })
        .finally(() => {
          showLoader(false);
        });
    }
  }, [idUser, page]);

  React.useEffect(() => { getUsersJobs(); }, [getUsersJobs]);

  const handlePageClick = (newPage) => {
    setPage(newPage);
  };

  const handlePictureChange = async (e) => {
    const { files } = e.target;
    const newFile = files[0];
    if (newFile) {
      if (acceptedFiles.includes(newFile.name.split('.').pop().toLowerCase())) {
        const request = await CreateAuthRequest('POST', null, false);
        const body = new FormData();
        body.append('image', newFile);
        request.body = body;
        fetchData('me/image', request)
          .then((response) => {
            setUserInfo(response);
            // document.location.reload();
            updateUser(response);
          });
        document.getElementById('profile-pricture').value = '';
      } else {
        NotificationManager.warning('This type of file is not allowed.', 'Warning!', 5000);
      }
    }
  };

  const handleUpdateLicense = (license) => {
    setUserInfo({ ...userInfo, user_license: license });
  };

  return (
    <div className="user-details">
      <Header
        title="User profile"
        right={() => (
          <>
            <Link to={`/pannel/users/change_password/${idUser}/profile`} className="btn btn-primary btn-radius" style={{ marginRight: '5px' }}>Change password</Link>
            <Link to={`/pannel/users/edit/${idUser}/profile`} className="btn btn-primary btn-radius">Edit User</Link>
          </>
        )}
      />
      <div className="mg-top card">
        <div className="card-body">
          <div className="row">
            <div className="col-12 col-md-4">
              <label>Profile Picture</label>
              <div className="img" style={{ backgroundImage: userInfo.image_url && `url('${process.env.REACT_APP_API_URL}${userInfo.image_url.slice(1, userInfo.image_url.length)}')` }}>
                <label className="w-100">
                  <div className="placeholder">
                    <i className="fas fa-camera" />
                  </div>
                  <input
                    id="profile-pricture"
                    type="file"
                    className="hide"
                    accept="jpg, png, jpeg, svg, gif"
                    onChange={handlePictureChange}
                  />
                </label>
              </div>
            </div>
            <div className="col-12 col-md-8">
              <div className="row">
                <div className="col-12 col-md-6">
                  <label>Name</label>
                  <p>{`${userInfo.name} ${userInfo.last_name}`}</p>
                </div>
                <div className="col-12 col-md-6">
                  <label>Type</label>
                  <p>{userType(userInfo.type)}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6">
                  <label>Email</label>
                  <p>{userInfo.email}</p>
                </div>
                <div className="col-12 col-md-6">
                  <label>Phone number</label>
                  <p>{userInfo.phone_number ? userInfo.phone_number : 'N/A'}</p>
                </div>
              </div>
            </div>
          </div>
          <hr />

          <LicenseUser
            idUser={idUser}
            licenseInfo={userInfo.user_license}
            update={handleUpdateLicense}
            NotificationManager={NotificationManager}
            isProfile
          />
          <hr />

          <h5>Jobs List</h5>
          <div className="table-section">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Section</th>
                    <th>Job Title</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    jobList.count > 0
                      ? jobList.rows.map((job) => (
                        <tr>
                          <td>{job.section.name}</td>
                          <td>{job.title}</td>
                        </tr>
                      ))
                      : <tr><td colSpan="3">0 jobs found</td></tr>
                  }
                </tbody>
              </table>

              <Pagination
                total={jobList.count}
                page={page}
                limit={10}
                pageClick={handlePageClick}
                showLimit={false}
              />

            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

Profile.propTypes = {
  NotificationManager: PropTypes.object,
  showLoader: PropTypes.func,
};

Profile.defaultProps = {
  NotificationManager: null,
  showLoader: (f) => f,
};
export default Profile;
