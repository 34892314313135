/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Container from '../views/PanelContainer/containter';

const PrivateRoute = () => {
  const { token } = window.sessionStorage;
  const RedirectToLogin = () => {
    sessionStorage.removeItem('token');
    return (<Redirect to="/login" />);
  };
  return (
    token && token !== 'undefined'
      ? <Route path="/" component={Container} />
      : RedirectToLogin()
  );
};

export default PrivateRoute;
