/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import { user, UserContext } from './userContext';
import { fetchData, CreateAuthRequest } from '../../API/constants';

const UserContextProvider = ({ children }) => {
  const [state, setUser] = React.useState(user);

  const getUserInfo = React.useCallback(async () => {
    const request = await CreateAuthRequest('GET', null, true);
    fetchData('me', request)
      .then((response) => {
        setUser(response);
      });
  }, [fetchData, CreateAuthRequest]);

  React.useEffect(() => { getUserInfo(); }, [getUserInfo]);

  return (
    <UserContext.Provider value={{ user: state, updateUser: setUser }}>
      {children}
    </UserContext.Provider>
  );
};

UserContextProvider.propTypes = {
  children: PropTypes.object,
};

UserContextProvider.defaultProps = {
  children: null,
};

export default UserContextProvider;
