/* eslint-disable import/no-cycle */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable arrow-parens */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import TodoElm from './todoElm';

const ListLevelN = ({
  dropDrag,
  elmList: list,
  parent,
  draggable,
  setDraggable,
  dragingOverElm,
  setDraggingOverElm,
  handleEditItem,
  handleIndent,
  handleOutdent,
}) => {
  const [items, setItems] = React.useState([]);
  const [elmList, setElementList] = React.useState({});

  React.useEffect(() => {
    setElementList(list);
    setItems(list.list_items.sort((a, b) => a.order - b.order));
  }, [list]);

  return (
    <div
      id={`list-${list.id_list_item}`}
      className="body-todo"
      onDrop={dropDrag}
      onDragOver={(e) => { e.preventDefault(); }}
    >
      {
        items.map(item => (
          <TodoElm
            key={item.id_list_item}
            idTask={elmList.id_task}
            elm={item}
            parent={parent}
            handleEditItem={handleEditItem}
            handleIndent={handleIndent}
            handleOutdent={handleOutdent}
            draggable={draggable}
            setDraggable={setDraggable}
            dropDrag={dropDrag}
            dragingOverElm={dragingOverElm}
            setDraggingOverElm={setDraggingOverElm}
          />
        ))
      }
    </div>
  );
};

ListLevelN.propTypes = {
  elmList: PropTypes.object,
  parent: PropTypes.object.isRequired,
  draggable: PropTypes.object,
  setDraggable: PropTypes.func,
  dropDrag: PropTypes.func,
  dragingOverElm: PropTypes.object,
  setDraggingOverElm: PropTypes.func,
  handleEditItem: PropTypes.func,
  handleIndent: PropTypes.func,
  handleOutdent: PropTypes.func,
};

ListLevelN.defaultProps = {
  elmList: {
    id_list: 0,
    list_items: [],
  },
  draggable: null,
  setDraggable: f => f,
  dragingOverElm: null,
  setDraggingOverElm: f => f,
  dropDrag: f => f,
  handleEditItem: f => f,
  handleIndent: f => f,
  handleOutdent: f => f,
};

export default ListLevelN;
