/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import {
  Route, Switch, Redirect, BrowserRouter,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import SideBarContextProvider from './context/sidebar/sidebarProvider';
import PrivateRoute from './Routes/privateRoute';
import Login from './views/Login/login';
import Recovery from './views/Login/recoveryPassword';
import ResetPassword from './views/Login/resetPassword';
import store from './Reducers/store';
import './App.css';
import './dateTime.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-notifications/lib/notifications.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
// import 'react-bootstrap-typeahead/css/Typeahead.css';

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <SideBarContextProvider>
          <BrowserRouter>
            <Switch>
              <Redirect exact from="/" to="/login" />
              <Route exact path="/login" component={Login} />
              <Route exact path="/recovery_password" component={Recovery} />
              <Route exact path="/change_password/:userEmail/:token" component={ResetPassword} />
              <PrivateRoute />
            </Switch>
          </BrowserRouter>
        </SideBarContextProvider>
      </div>
    </Provider>
  );
}

export default App;
