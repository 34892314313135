/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable arrow-parens */
import React from 'react';
import PropTypes from 'prop-types';
import useDebounce from '../../hooks/useDebounce';
import { fetchData, CreateAuthRequest } from '../../API/constants';
import './typeahead.scss';

const TypeaheadWithOptions = ({
  url,
  limit,
  labelKey,
  idLabel,
  renderOptions,
  className,
  defaultSelected,
  onSelect,
  placeholder,
  inputProps,
  filterBy,
  showResultsOnEmpty,
}) => {
  const [search, setSearch] = React.useState('');
  const [results, setResults] = React.useState({ count: 0, rows: [] });
  const [selected, setSelected] = React.useState(defaultSelected);
  const debounce = useDebounce(search, 300);

  React.useEffect(() => {
    if (defaultSelected) {
      setSelected(defaultSelected);
      if (typeof labelKey === 'function') {
        setSearch(labelKey(defaultSelected));
      } else {
        setSearch(defaultSelected[labelKey]);
      }
      setResults({ count: 0, rows: [] });
    }
  }, [defaultSelected]);

  const getData = React.useCallback(async () => {
    console.log(url);
    if (url) {
      // setSelected(null);
      // setSearch('');
      const request = await CreateAuthRequest();
      fetchData(`${url}?limit=${limit}&search=${debounce}&${filterBy}`, request)
        .then(response => {
          if (response.rows.length > 5) {
            setResults({ ...response, rows: response.rows.slice(0, 5) });
          } else {
            setResults(response);
          }
        });
    }
  }, [url, debounce]);

  React.useEffect(() => {
    if (selected) {
      if (typeof labelKey === 'function') {
        if (labelKey(selected) !== debounce) {
          getData();
        }
      } else if (selected[labelKey] !== debounce) {
        getData();
      }
    } else {
      getData();
    }
  }, [getData]);

  React.useEffect(() => {
    if (inputProps) {
      if (inputProps.hasOwnProperty('disabled')) {
        if (inputProps.disabled) {
          setSearch('');
          setSelected(null);
          setResults({ count: 0, rows: [] });
        }
      }
    }
  }, [inputProps]);

  const handleOnChange = (e) => {
    setSearch(e.target.value);
    if (selected) {
      setSelected(null);
      onSelect(null);
    }
  };

  const onSelectItem = (result) => {
    setSelected(result);
    onSelect(result);
    if (typeof labelKey === 'function') {
      setSearch(labelKey(result));
    } else {
      setSearch(result[labelKey]);
    }
    setResults({ count: 0, rows: [] });
  };

  return (
    <div className={`typeahead-cuztom ${className}`}>
      <input
        className="form-control input-shadow"
        placeholder={placeholder}
        value={search}
        onChange={handleOnChange}
        {...inputProps}
      // onKeyPress={(e) => handlePressEnterKey(e.key)}
      />
      <div className="typeahead-cuztom-results">
        {
          // ((search.length === 0 && showResultsOnEmpty) || (search.length > 0))
          // && 
          results.rows.map(result => (
            <div
              key={result[idLabel]}
              className="typeahead-cuztom-result"
              onClick={() => onSelectItem(result)}
            >
              {renderOptions
                ? renderOptions(result)
                : result[labelKey]}
            </div>
          ))
        }
      </div>
    </div>
  );
};

TypeaheadWithOptions.propTypes = {
  url: PropTypes.string.isRequired,
  labelKey: PropTypes.string.isRequired,
  idLabel: PropTypes.string.isRequired,
  limit: PropTypes.number,
  renderOptions: PropTypes.func,
  className: PropTypes.string,
  defaultSelected: PropTypes.object,
  onSelect: PropTypes.func,
  placeholder: PropTypes.string,
  inputProps: PropTypes.object,
  filterBy: PropTypes.string,
  showResultsOnEmpty: PropTypes.bool,
};

TypeaheadWithOptions.defaultProps = {
  limit: 5,
  renderOptions: null,
  className: '',
  defaultSelected: null,
  onSelect: f => f,
  placeholder: 'Type to search ...',
  inputProps: null,
  filterBy: null,
  showResultsOnEmpty: true,
};

export default TypeaheadWithOptions;
