/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import Header from '../../components/header/header';
import Typeahead from '../../components/CustomizedTypeahead/typeahead';
import { CreateAuthRequest, fetchData } from '../../API/constants';

const maxDescriptionLength = 65000;

const NewJob = ({ NotificationManager }) => {
  const history = useHistory();
  const [validated, setValidated] = React.useState(false);
  const [form, setForm] = React.useState({ is_recruiting: true, editorMKDN: EditorState.createEmpty() });
  const [jobInfo, setJobInfo] = React.useState(null);
  const { idJob } = useParams();
  const isEditing = !!idJob;

  const getJobInfo = React.useCallback(async () => {
    if (idJob) {
      const request = await CreateAuthRequest('GET', null, true);
      fetchData(`sections/jobs/${idJob}`, request)
        .then((response) => {
          const infoJob = {
            title: response.title,
            abstract: response.abstract,
            is_recruiting: response.is_recruiting,
          };
          if (response.description) {
            const markdownString = response.description;
            const rawData = markdownToDraft(markdownString);
            const contentState = convertFromRaw(rawData);
            infoJob.editorMKDN = EditorState.createWithContent(contentState);
          }
          setForm(infoJob);
          setJobInfo(response);
        });
    }
  }, [idJob]);
  React.useEffect(() => { getJobInfo(); }, [getJobInfo]);

  const handleFormChange = ({ target }) => {
    const { name, value } = target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setValidated(true);
    if (e.target.checkValidity()) {
      const formToSend = { ...form };
      const rawContentState = convertToRaw(form.editorMKDN.getCurrentContent());
      const description = draftToMarkdown(rawContentState);
      formToSend.description = description;
      delete formToSend.editorMKDN;
      if (isEditing) {
        const request = await CreateAuthRequest('PUT', formToSend, true);
        fetchData(`sections/jobs/${idJob}`, request)
          .then(() => {
            NotificationManager.success('Sectionhas bean updated.', 'Success!', 5000);
            history.replace(`/pannel/jobs/details/${idJob}`);
            // setForm({
            //   id_section: response.id_section,
            //   title: response.title,
            //   description: response.description,
            //   abstract: response.abstract,
            //   is_recruiting: response.is_recruiting,
            // });
          });
      } else {
        const request = await CreateAuthRequest('POST', formToSend, true);
        fetchData('sections/jobs', request)
          .then((response) => {
            history.replace(`/pannel/jobs/details/${response.id_job}`);
            NotificationManager.success('Sectionhas bean created.', 'Success!', 5000);
          })
          .catch((error) => {
            try {
              error.then((response) => {
                NotificationManager.error(response.message, 'Error!', 5000);
              });
            } catch (error1) {
              NotificationManager.error(error1, 'Error!', 5000);
            }
          });
      }
    }
  };

  return (
    <div className="nkdx">
      <Header
        title={isEditing ? 'Edit Job' : 'Create Job'}
      />
      <div className="card">
        <div className="card-body">
          <form className={`form needs-validation ${validated ? ' was-validated' : ''}`} onSubmit={handleSubmit} noValidate>
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label>Section</label>
                  {
                    jobInfo
                      ? <p>{jobInfo.section.name}</p>
                      : (
                        <Typeahead
                          labelKey="name"
                          url={`${process.env.REACT_APP_API_URL}sections`}
                          multiple={false}
                          minLength={3}
                          onChange={(option) => handleFormChange({ target: { name: 'id_section', value: option[0]?.id_section} })}
                          placeholder="Filter by section..."
                          itemChildren={(option) => (
                            <span>{`${option.id_section} ${option.name}`}</span>
                          )}
                          inputProps={{ required: 'required' }}
                        />
                      )
                  }
                </div>
              </div>
              <div className="col-md-8 col-12">
                <div className="form-group">
                  <label>Title</label>
                  <input className="form-control input-shadow" placeholder="Type job title" name="title" value={form.title} onChange={handleFormChange} required />
                </div>
              </div>
              <div className="col-md-12 col-12">
                <div className="form-group">
                  <label>Abstract</label>
                  <textarea rows="5" className="form-control input-shadow" placeholder="Type contact person name" name="abstract" value={form.abstract} onChange={handleFormChange} />
                </div>
              </div>
              <div className="col-md-12 col-12">
                <div className="form-group">
                  <label>Description</label>
                  <Editor
                    handleBeforeInput={(val) => {
                      const textLength = form.editorMKDN.getCurrentContent().getPlainText().length;
                      if (val && textLength >= maxDescriptionLength) {
                        return 'handled';
                      }
                      return 'not-handled';
                    }}
                    handlePastedText={(val) => {
                      const textLength = form.editorMKDN.getCurrentContent().getPlainText().length;
                      return ((val.length + textLength) >= maxDescriptionLength);
                    }}
                    toolbar={
                      {
                        options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'emoji', 'history'],
                        inline: {
                          inDropdown: false,
                          className: undefined,
                          component: undefined,
                          dropdownClassName: undefined,
                          options: ['bold', 'italic', 'underline', 'strikethrough'],
                        },
                        textAlign: { inDropdown: true },
                        link: { inDropdown: true },
                      }
                    }

                    editorState={form.editorMKDN}
                    wrapperClassName="border input-shadow"
                    onEditorStateChange={(value) => handleFormChange({ target: { name: 'editorMKDN', value } })}
                  />
                </div>
              </div>
              <div className="col-12 col-md-12">
                <div className="form-group">
                  <label>Is recruiting? </label>
                  <button className="btn btn-primary" style={{ padding: '2px 7px', marginLeft: '5px' }} type="button" onClick={() => handleFormChange({ target: { name: 'is_recruiting', value: !form.is_recruiting } })}>
                    <i className={form.is_recruiting ? 'fas fa-check-square' : 'far fa-square'} />
                  </button>

                </div>
              </div>

            </div>
            <div className="mg-top tac">
              <button type="submit" className="btn btn-primary btn-radius">{isEditing ? 'Save' : 'Create'}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

NewJob.propTypes = {
  NotificationManager: PropTypes.object,
};

NewJob.defaultProps = {
  NotificationManager: null,
};

export default NewJob;
