/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable arrow-parens */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import useDebounce from '../../hooks/useDebounce';
import { propTypes } from 'react-bootstrap/esm/Image';

const InputDebounce = ({
  icon, placeholder, handleInputChange, value, keyInput, onEnter, inputProps,
}) => {
  const [search, setSearch] = React.useState(value);
  const debounce = useDebounce(search, 300);

  React.useEffect(() => {
    if (value === '') {
      setSearch('');
    }
  }, [value]);

  React.useEffect(() => {
    if (inputProps) {
      if (inputProps.hasOwnProperty('disabled')) {
        if (inputProps.disabled) {
          setSearch('');
        }
      }
    }
  }, [inputProps]);

  React.useEffect(() => {
    handleInputChange(debounce);
  }, [debounce]);

  const handleOnChange = ({ target }) => {
    setSearch(target.value);
  };

  const handlePressEnterKey = (key) => {
    if (key === 'Enter') {
      onEnter();
    }
  };

  return (
    <div className="input-group input-shadow" key={keyInput}>
      {
        icon
        && (
          <div className="input-group-prepend ">
            <span className="input-group-text"><i className={icon} /></span>
          </div>
        )
      }
      <input
        className="form-control"
        placeholder={placeholder}
        value={search}
        onChange={handleOnChange}
        onKeyPress={(e) => handlePressEnterKey(e.key)}
        {...inputProps}
      />
    </div>
  );
};

InputDebounce.propTypes = {
  handleInputChange: PropTypes.func.isRequired,
  icon: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  keyInput: PropTypes.string,
  onEnter: PropTypes.func,
  inputProps: PropTypes.object,
};

InputDebounce.defaultProps = {
  inputProps: null,
  icon: 'fas fa-search',
  placeholder: 'Type to search...',
  value: '',
  keyInput: '1',
  onEnter: f => f,
};

export default InputDebounce;
