/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable arrow-parens */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable object-curly-newline */
import React from 'react';
import PropTypes from 'prop-types';
import { Map, TileLayer, Marker } from 'react-leaflet';
import { generateMarker } from './markers';
import 'leaflet/dist/leaflet.css';
import styles from './staticMap.module.css';

const targetIcon = generateMarker({ icon: 'default' });

const StaticMap = ({ positionProp, initialPos, height, showShadow, zoom, handleOnClick }) => {
  const [map, setMap] = React.useState(() => ({
    zoom,
    position: initialPos,
  }));

  const [position, setPosition] = React.useState(null);

  React.useEffect(() => {
    setMap({
      zoom,
      position: positionProp,
    });
    setPosition(positionProp);
  }, [positionProp]);

  return (
    <div data-testid="LeafletCoordinateMap" className={`${showShadow && 'mirai-shadow'} ${styles.wrapper}`}>
      <div className={styles['map-wrapper']} style={{ height }}>
        <Map
          center={map.position}
          zoom={map.zoom}
          maxZoom={20}
          animate
          bounds={map.bounds}
          className={styles['leaflet-container']}
          onclick={handleOnClick}
        >
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a>'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {/* {initialPos && !position && <Marker
            position={initialPos} title="Ubicación" icon={poi ? poiIcon : targetIcon} />} */}
          {position && <Marker position={position} title="Ubicación" icon={targetIcon} />}
        </Map>
      </div>
    </div>
  );
};

StaticMap.propTypes = {
  height: PropTypes.string,
  initialPos: PropTypes.arrayOf(PropTypes.number),
  poi: PropTypes.bool,
  showShadow: PropTypes.bool,
  positionProp: PropTypes.arrayOf(PropTypes.number),
  zoom: PropTypes.number,
  handleOnClick: PropTypes.func,
};

StaticMap.defaultProps = {
  height: '20rem',
  poi: false,
  showShadow: true,
  positionProp: [29.07489967, -110.95853424],
  zoom: 12,
  handleOnClick: f => f,
};

export default StaticMap;
