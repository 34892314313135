/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

const Header = ({ title, right }) => {
  const history = useHistory();

  const goBack = () => {
    // TODO:  preguntar por guardar cambios
    history.goBack();
  };

  return (
    <div className="row" style={{ margin: '0' }}>
      <div className="col">
        <h3>
          <i className="clickable fas fa-arrow-left" onClick={goBack} />
          {' '}
          {title}
        </h3>
      </div>
      {
        right
        && right()
      }
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  right: PropTypes.func,
};

Header.defaultProps = {
  title: '',
  right: null,
};

export default Header;
