/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable arrow-parens */
import React from 'react';
import PropTypes from 'prop-types';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { CreateAuthRequest } from '../../API/constants';

const CustomTypeahead = ({
  placeholder,
  labelKey, multiple,
  minLength, url,
  filterBy,
  itemChildren,
  onChange, inputProps,
}) => {
  const [options, setOptions] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleOnSearch = React.useCallback(async (search) => {
    setIsLoading(true);
    // if (defaultOptions.length > 0) {
    //   setOptions(defaultOptions);
    //   setIsLoading(false);
    // } else {
    const request = await CreateAuthRequest('GET', null, true);
    fetch(`${url}?search=${search}&${filterBy}`, request)
      .then((resp) => resp.json())
      .then(response => {
        setOptions(response.rows);
        setIsLoading(false);
      })
      .catch(console.error);
    // }
  }, []);

  const renderMenuItemChildren = (option) => itemChildren(option);

  const handleOnchange = (elm) => {
    onChange(elm);
  };

  const propsAs = {};
  if (inputProps) {
    propsAs.inputProps = inputProps;
  }

  return (
    <AsyncTypeahead
      className="input-shadow"
      id={`ath${labelKey}`}
      labelKey={labelKey}
      multiple={multiple}
      minLength={minLength}
      onChange={handleOnchange}
      isLoading={isLoading}
      // filterBy={['first_name', 'last_name', 'username']}
      clearButton
      searchText="Searching..."
      options={options}
      onSearch={handleOnSearch}
      placeholder={placeholder}
      flip
      renderMenuItemChildren={itemChildren && ((option) => renderMenuItemChildren(option))}
      {...propsAs}
      positionFixed
    />
  );
};

CustomTypeahead.propTypes = {
  placeholder: PropTypes.string,
  labelKey: PropTypes.string || PropTypes.func,
  multiple: PropTypes.bool,
  minLength: PropTypes.number,
  url: PropTypes.string,
  filterBy: PropTypes.string,
  itemChildren: PropTypes.func,
  defaultOptions: PropTypes.array,
  onChange: PropTypes.func,
  inputProps: PropTypes.object,
  defaultSelected: PropTypes.object,
};

CustomTypeahead.defaultProps = {
  placeholder: 'Type to search..',
  labelKey: '',
  multiple: false,
  minLength: 3,
  url: '',
  filterBy: '',
  itemChildren: f => f,
  defaultOptions: [],
  onChange: f => f,
  inputProps: null,
  defaultSelected: null,
};

export default CustomTypeahead;
