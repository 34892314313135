/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { NotificationManager, NotificationContainer } from 'react-notifications';
import loaderConnect from '../../components/Loader/loaderConnect';
import Loader from '../../components/Loader/loader';
// import logo from '../../assets/logo192.png';
import './login.scss';

const RecoveryPassword = (props) => {
  const { showLoader } = props;
  const history = useHistory();
  const [email, setEmail] = React.useState('');
  const [alertObjShow, setAlertObj] = React.useState(false);

  function ChangeValues({ target }) {
    setEmail(target.value);
  }

  const Submit = async (e) => {
    e.preventDefault();
    if (e.target.checkValidity()) {
      showLoader(true);
      const request = {
        method: 'POST',
        headers: new Headers({ 'Content-Type': 'application/json' }),
        body: JSON.stringify({ email }),
      };

      await fetch(`${process.env.REACT_APP_API_URL}auth/password/request/email`, request)
        .then((response) => response.json())
        .then((response) => {
          if (response.message) {
            NotificationManager.error(response.message, 'Error!', 7000);
          } else {
            setAlertObj(true);
          }
        })
        .catch((error) => {
          console.error(error);
          NotificationManager.error(error, 'Error!', 7000);
        })
        .finally(() => { showLoader(false); });
    } else {
      NotificationManager.warning('Email required.', 'Warning!', 5000);
    }
  };

  return (
    (
      <div className="login">
        <div className="contenedor">
          <div className="card">
            <div className="logo tac">
              <h3 className="brand">Power of one</h3>
              {/* <img style={{ width: 'auto', height: '90%', marginTop: '5px' }} src={logo} alt="Áreas comunes" /> */}
            </div>
            <div className="card-body">
              <div className="tac">
                <label className="welcome">Recovery password</label>
                <p className="welcome">You will receive instructions on how to change your password via email</p>
              </div>
              {
                alertObjShow
                  ? (
                    <div className="alert alert-success" role="alert">
                      <label>Success! Please check your email to see the instructions to change password.</label>
                    </div>
                  )
                  : (
                    <form onSubmit={Submit}>
                      <div className="form-group tal">
                        <label className="label-title">Email</label>
                        <input
                          id="email"
                          name="email"
                          type="email"
                          value={email.email}
                          onChange={ChangeValues}
                          className="form-control"
                          placeholder="Type your email to recovery"
                          required
                        />
                      </div>
                      <div className="">
                        <button type="submit" className="btn btn-primary form-control btn-radius">Submit</button>
                      </div>
                    </form>
                  )
              }

              <div className="div-submit">
                <button type="button" className="btn btn-info form-control btn-radius" onClick={() => history.push('/login')}>Go back to login</button>
              </div>

            </div>
          </div>
        </div>
        <NotificationContainer />
        <Loader />
      </div>
    )
  );
};

RecoveryPassword.propTypes = {
  showLoader: PropTypes.func.isRequired,
};

export default loaderConnect(RecoveryPassword);
