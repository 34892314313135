/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';

const ModalCancelNotification = ({ show, onHide, onConfirm }) => (
  <Modal show={show} onHide={onHide} centered>
    <Modal.Header closeButton>
      <Modal.Title>Confirm cancel notification</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <h3>Are you sure you want to cancel this notification?</h3>
      <p>This action cannot be undone</p>
    </Modal.Body>
    <Modal.Footer>
      <button type="button" className="btn btn-default btn-radius" onClick={() => onHide()}>Close</button>
      <button type="submit" className="btn btn-danger btn-radius" onClick={onConfirm}>Confirm</button>
    </Modal.Footer>
  </Modal>
);

ModalCancelNotification.propTypes = {
  show: PropTypes.bool,
  onConfirm: PropTypes.func,
  onHide: PropTypes.func,
};

ModalCancelNotification.defaultProps = {
  show: false,
  onConfirm: (f) => f,
  onHide: (f) => f,
};

export default ModalCancelNotification;
