/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable camelcase */
/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import Header from '../../components/header/header';
import { CreateAuthRequest, fetchData } from '../../API/constants';
import './notifications.scss';

const DetailsNotification = ({ showLoader }) => {
  const { idNotification } = useParams();
  const [notificationInfo, setNotificationInfo] = React.useState({
    title: '',
    type: '',
    message: '',
    createdAt: null,
  });

  const getNotificationInfo = React.useCallback(async () => {
    showLoader(true);
    const request = await CreateAuthRequest('GET', null, true);
    fetchData(`notifications/${idNotification}`, request)
      .then((response) => {
        setNotificationInfo(response);
      })
      .finally(() => { showLoader(false); });
  }, [idNotification]);

  React.useEffect(() => {
    getNotificationInfo();
  }, [getNotificationInfo]);

  const isUrl = (str) => /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(str);

  const hasHTTP = (str) => {
    if (str.includes('http')) {
      return str;
    }
    return `//${str}`;
  };

  const formatMessage = (msg) => {
    const res = msg.split(' ');
    const helper = [];
    for (let i = 0; i < res.length; i += 1) {
      const element = res[i];
      if (isUrl(element)) {
        const url = hasHTTP(element);
        helper.push(<a href={url} target="blank">{element}</a>);
        helper.push(' ');
      } else {
        helper.push(`${element} `);
      }
    }
    return helper;
  };

  const getLinkTotask = () => {
    const { id_reference, metadata } = notificationInfo;
    let url = '';
    if (metadata.id_task_parent) {
      url = `/pannel/tasks/detail/${metadata.id_task_parent}/subtask/${id_reference}`;
    } else {
      url = `/pannel/tasks/detail/${id_reference}`;
    }
    return <a href={url}>{url}</a>;
  };

  return (
    <div className="details-notification">
      <Header
        title="Details Notification"
      />
      <div className="card">
        <div className="card-header">
          <h4>
            <small>Title: </small>
            {notificationInfo.title}
          </h4>
        </div>
        <div className="card-body">
          <div className="form-group">
            <label>Message</label>
            <p>{formatMessage(notificationInfo.message)}</p>
          </div>
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="form-group">
                <label>Type</label>
                <p>{notificationInfo.type}</p>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="form-group">
                <label>Date to send</label>
                <p>{notificationInfo.sent_date ? moment(notificationInfo.sent_date).tz('Atlantic/Bermuda').format('DD/MMM/YYYY hh:mm A') : 'Not assigned'}</p>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="form-group">
                <label>Created date</label>
                <p>{notificationInfo.createdAt && moment(notificationInfo.createdAt).tz('Atlantic/Bermuda').format('DD/MMM/YYYY hh:mm A')}</p>
              </div>
            </div>
          </div>
          {
            notificationInfo.type === 'Tasks'
            && (
              <div className="row">
                <div className="col-12 col-md-4">
                  <div className="form-group">
                    <label>Link to task</label>
                    <p>{getLinkTotask()}</p>
                  </div>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
};

DetailsNotification.propTypes = {
  showLoader: PropTypes.func,
  NotificationManager: PropTypes.object,
};

DetailsNotification.defaultProps = {
  showLoader: (f) => f,
  NotificationManager: null,
};

export default DetailsNotification;
