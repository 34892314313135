/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './style.scss';

const Loader = ({ showLoader }) => (
  <div className={`loader-container ${showLoader ? '' : 'hider'}`}>
    <div className="spinner-border" />
  </div>
);

Loader.propTypes = {
  showLoader: PropTypes.bool,
};

Loader.defaultProps = {
  showLoader: false,
};

export default connect((state) => ({
  showLoader: state.loader.show,
}), {})(Loader);
