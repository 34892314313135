/* eslint-disable global-require */
/* eslint-disable import/prefer-default-export */
import Leaflet from 'leaflet';

const iconList = {
  default: require('../../assets/images/icon_ball.png'),
  ball: require('../../assets/images/icon_ball.png'),
};

export function generateMarker({
  className = 'marker',
  iconSize = [15, 15],
  iconAnchor = [7, 7],
  icon = 'default',
  alt = 'Marker',
}) {
  return Leaflet.icon({
    className,
    iconSize,
    iconAnchor,
    iconUrl: iconList[icon],
  });
}
