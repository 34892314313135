import React from 'react';

export const chat = {
  selectedChat: null,
};

export const updateChat = () => { };

export const ChatContext = React.createContext({
  chat,
  updateChat,
});
