/* eslint-disable arrow-parens */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';

const config = {
  default: { text: 'Delete', color: 'danger' },
  delete: { text: 'Delete', color: 'danger' },
  change: { text: 'Change', color: 'warning' },
  activate: { text: 'Activate', color: 'success' },
  deactivate: { text: 'Deactivate', color: 'danger' },
  cancel: { text: 'Cancel', color: 'danger' },
  resolve: { text: 'Resolver', color: 'danger' },
  unassign: { text: 'Unassign', color: 'danger' },
};

const ModalConfirm = (props) => {
  const {
    show, message, onHide, onConfirm, type, optionalMessage,
  } = props;
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          {`Confirm ${config[type].text}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h3>
          {`Are you sure you want to ${config[type].text.toLowerCase()} `}
          <b>{message}</b>
          {' ?'}
        </h3>
        {optionalMessage && <p>{optionalMessage}</p>}
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-default btn-radius" onClick={() => onHide()}>Close</button>
        <button type="button" className={`btn btn-${config[type].color} btn-radius`} onClick={() => onConfirm()}>{config[type].text}</button>
      </Modal.Footer>
    </Modal>
  );
};

ModalConfirm.propTypes = {
  type: PropTypes.string,
  optionalMessage: PropTypes.string,
  show: PropTypes.bool,
  message: PropTypes.string,
  onConfirm: PropTypes.func,
  onHide: PropTypes.func,
};

ModalConfirm.defaultProps = {
  type: 'default',
  optionalMessage: null,
  show: false,
  message: '',
  onConfirm: f => f,
  onHide: f => f,
};

export default ModalConfirm;
