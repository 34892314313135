/* eslint-disable import/no-extraneous-dependencies */
import { createStore, combineReducers } from 'redux';
// import { composeWithDevTools } from 'redux-devtools-extension';

// app-reducers
// import notificationReducer from './notificationReducer';
import loaderReducer from './loaderReducer';

const mainReducer = combineReducers({
  // notification: notificationReducer,
  loader: loaderReducer,
});

export default createStore(mainReducer);
