/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import { useParams, Link, useLocation } from 'react-router-dom';
import { CreateAuthRequest, fetchData } from '../../API/constants';
import Header from '../../components/header/header';
import LicenseUser from './license';
import JobsBySection from './jobs';
import UserSections from './sections';
import ModalConfirm from '../../components/ModalConfirm/modalConfirm';
import './users.scss';

const acceptedFiles = ['jpg', 'png', 'jpeg', 'svg', 'gif'];

const userType = (t) => {
  let text = 'Not found';
  switch (t) {
    case 1:
      text = "Admin";
      break;
    case 2:
      text = "Supervisor";
      break;
    case 3:
      text = "Employee";
      break;

    default:
      break;
  }
  return text;
};

const DetailsUser = ({ showLoader, NotificationManager }) => {
  const { idUser } = useParams();
  const location = useLocation();
  const [userInfo, setUserInfo] = React.useState({});
  const [modalConfirm, setModalConfirm] = React.useState(false);

  const isProfile = location.pathname.includes('profile');

  const getUserInfo = React.useCallback(async () => {
    if (idUser) {
      showLoader(true);
      const request = await CreateAuthRequest('GET', null, true);
      fetchData(`users/${idUser}`, request)
        .then((response) => {
          setUserInfo(response);
        })
        .finally(() => {
          showLoader(false);
        });
    }
  }, [idUser, showLoader, fetchData, CreateAuthRequest]);

  React.useEffect(() => { getUserInfo(); }, [getUserInfo]);

  const handlePictureChange = async (e) => {
    const { files } = e.target;
    const newFile = files[0];
    if (newFile) {
      if (acceptedFiles.includes(newFile.name.split('.').pop().toLowerCase())) {
        const request = await CreateAuthRequest('POST', null, false);
        const body = new FormData();
        body.append('image', newFile);
        request.body = body;
        fetchData(`users/${idUser}/image`, request)
          .then((response) => {
            setUserInfo(response);
          });
        document.getElementById('profile-pricture').value = '';
      } else {
        NotificationManager.warning('This type of file is not allowed.', 'Warning!', 5000);
      }
    }
  };

  const handleUpdateLicense = (license) => {
    setUserInfo({ ...userInfo, user_license: license });
  };

  const hideModal = () => {
    setModalConfirm(false);
  };

  const showModal = () => {
    setModalConfirm(true);
  };

  const DeactivateUser = async () => {
    const request = await CreateAuthRequest('PUT', { disabled: !userInfo.disabled }, true);
    fetchData(`users/${idUser}`, request)
      .then(() => {
        setUserInfo({ ...userInfo, disabled: !userInfo.disabled });
        NotificationManager.success('User updated successfully.', 'Success!', 5000);
      })
      .catch((err) => {
        try {
          err.then((er) => {
            NotificationManager.error(er.message, 'Error!', 5000);
          });
        } catch (error) {
          NotificationManager.error(error, 'Error!', 5000);
        }
      })
      .finally(() => {
        showLoader(false);
        hideModal();
      });
  };

  return (
    <div className="user-details">
      <Header
        title="User details"
        right={() => (
          <>
            <buton type="button" style={{ marginRight: '5px' }} className={`btn btn-${userInfo.disabled ? 'success' : 'danger'} btn-radius`} onClick={showModal}>{userInfo.disabled ? 'Activate' : 'Deactivate'}</buton>
            <Link to={`/pannel/users/change_password/${idUser}`} className="btn btn-primary btn-radius" style={{ marginRight: '5px' }}>Change password</Link>
            <Link to={`/pannel/users/edit/${idUser}`} className="btn btn-primary btn-radius">Edit User</Link>
          </>
        )}
      />
      <div className="mg-top card">
        <div className="card-body">
          <div className="row">
            <div className="col-12 col-md-4">
              <label>Profile Picture</label>
              <div className="img" style={{ backgroundImage: userInfo.image_url && `url('${process.env.REACT_APP_API_URL}${userInfo.image_url.slice(1, userInfo.image_url.length)}')` }}>
                <label className="w-100">
                  <div className="placeholder">
                    <i className="fas fa-camera" />
                  </div>
                  <input
                    id="profile-pricture"
                    type="file"
                    className="hide"
                    accept="jpg, png, jpeg, svg, gif"
                    onChange={handlePictureChange}
                  />
                </label>
              </div>
            </div>
            <div className="col-12 col-md-8">
              <div className="row">
                <div className="col-12 col-md-6">
                  <label>Name</label>
                  <p>{`${userInfo.name} ${userInfo.last_name}`}</p>
                </div>
                <div className="col-12 col-md-6">
                  <label>Type</label>
                  <p>{userType(userInfo.type)}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6">
                  <label>Email</label>
                  <p>{userInfo.email}</p>
                </div>
                <div className="col-12 col-md-6">
                  <label>Phone number</label>
                  <p>{userInfo.phone_number ? userInfo.phone_number : 'N/A'}</p>
                </div>
              </div>
            </div>
          </div>
          <hr />

          <LicenseUser
            idUser={idUser}
            licenseInfo={userInfo.user_license}
            update={handleUpdateLicense}
            NotificationManager={NotificationManager}
          />

          {
            userInfo.type !== 1
            && (
              <>
                <UserSections
                  showLoader={showLoader}
                  NotificationManager={NotificationManager}
                  idUser={idUser}
                  isProfile={isProfile}
                />

                <JobsBySection
                  showLoader={showLoader}
                  NotificationManager={NotificationManager}
                  idUser={idUser}
                  isProfile={isProfile}
                />
              </>
            )
          }

          <ModalConfirm
            show={modalConfirm}
            message={`${userInfo.name} ${userInfo.last_name}`}
            type={userInfo.disabled ? 'activate' : 'deactivate'}
            onHide={hideModal}
            onConfirm={DeactivateUser}
          />
        </div>
      </div>
    </div>
  );
};

DetailsUser.propTypes = {
  NotificationManager: PropTypes.object,
  showLoader: PropTypes.func,
};

DetailsUser.defaultProps = {
  NotificationManager: null,
  showLoader: (f) => f,
};
export default DetailsUser;
