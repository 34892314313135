/* eslint-disable arrow-parens */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import FileSaver from 'file-saver';
import { baseUrl } from '../../API/constants';
import ModalConfirm from '../../components/ModalConfirm/modalConfirm';

const FilesList = ({
  list, labelKey, idKey, handleDeleteFile,
}) => {
  const [showModal, setShowModal] = React.useState(false);
  const [fileToDelete, setFileToDelete] = React.useState(null);

  const getIcon = (fileName) => {
    const fileType = fileName.split('.').pop().toLowerCase();
    switch (fileType) {
      case 'pdf':
        return 'fas fa-file-pdf';
      case 'doc':
      case 'docx':
        return 'fas fa-file-word';
      case 'xls':
      case 'xlsx':
        return 'fas fa-file-excel';
      case 'png':
      case 'jpg':
      case 'jpeg':
      case 'svg':
      case 'gif':
        return 'fas fa-file-image';
      default:
        return 'fas fa-file';
    }
  };

  const Download = async (fileObj) => {
    const name = fileObj.original_filename;
    const request = { method: 'GET' };

    fetch(baseUrl + fileObj.file_url, request)
      .then(async response => {
        if (!response.message) {
          const file = await response.blob();
          FileSaver.saveAs(file, name);
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  const handleHideModal = () => {
    setShowModal(false);
    setFileToDelete(null);
  };

  const handleShowModal = (file) => {
    setShowModal(true);
    setFileToDelete(file);
  };

  const confirmDelete = () => {
    handleDeleteFile(fileToDelete);
    handleHideModal();
  };

  return (
    <div className="files-list">
      {
        list.map((elm) => (
          <div className="file-elm" key={elm[idKey]}>
            <div className="row">
              <div className="col-9">
                <i className={getIcon(elm[labelKey])} />
                <label>{elm[labelKey]}</label>
              </div>
              <div className="col tar">
                <button
                  type="button"
                  // href={baseUrl + elm.file_url}
                  // target="_blank"
                  // rel="noreferrer"
                  className="btnn"
                  onClick={() => Download(elm)}
                >
                  <i className="fas fa-download" />
                </button>
                <button type="button" className="btnn delete" onClick={() => handleShowModal(elm)}><i className="fas fa-trash-alt" /></button>
              </div>
            </div>
          </div>
        ))
      }
      <ModalConfirm
        show={showModal}
        type="delete"
        message={fileToDelete && fileToDelete[labelKey]}
        onHide={handleHideModal}
        onConfirm={confirmDelete}
      />
    </div>
  );
};

FilesList.propTypes = {
  labelKey: PropTypes.string.isRequired,
  idKey: PropTypes.string.isRequired,
  list: PropTypes.array,
  handleDeleteFile: PropTypes.func,
};

FilesList.defaultProps = {
  list: [],
  handleDeleteFile: f => f,
};

export default FilesList;
