/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import { ImageUser } from '../../components/ShowUsers/usersList';
import { ChatContext } from './chatContext/chatContext';
import useDebounce from '../../hooks/useDebounce';
import './chat.scss';
import { CreateAuthRequest, fetchData, makeUrlGET } from '../../API/constants';
import Typeahead from '../../components/CustomizedTypeahead/typeahead';
import { SocketContext } from '../../context/socketContext/socketContext';

const limit = 50;

const ChatList = ({ showLoader, update }) => {
  const { chat, updateChat } = React.useContext(ChatContext);
  const { socket, send } = React.useContext(SocketContext);
  const { selectedChat } = chat;
  const [search, setSearch] = React.useState('');
  const [usersList, setUsersList] = React.useState({ count: 0, rows: [] });
  const [url, setUrl] = React.useState('online');
  const [idSection, setIdSection] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const debounce = useDebounce(search, 300);

  const getConversations = React.useCallback(async () => {
    console.log('update', update);
    // showLoader(true);
    const request = await CreateAuthRequest('GET', null, true);
    fetchData(`chat/users/${url}?limit=${limit}&search=${encodeURIComponent(debounce)}&${makeUrlGET({ id_section: idSection, page })}`, request)
      .then((response) => {
        if (page === 1) {
          setUsersList(response);
        } else {
          setUsersList({ count: usersList.count, rows: [...usersList.rows, ...response.rows] });
        }
      })
      .finally(() => {
        showLoader(false);
      });
  }, [debounce, url, idSection, update, page]);

  React.useEffect(() => {
    getConversations();
  }, [getConversations]);

  const handleSelectChat = (user) => {
    updateChat({ selectedChat: user });
    send({
      action: 'active_user',
      payload: user.id_user,
    });
  };

  const handleSearch = ({ target }) => {
    setSearch(target.value);
  };

  const newConversation = () => {
    setUrl('available');
    setPage(1);
  };

  const handleChangeSectionFilter = (newId) => {
    setIdSection(newId);
  };

  const getActiveChat = (user) => {
    if (selectedChat) {
      if (user.id_user === selectedChat.id_user) {
        return 'active';
      }
      return '';
    }
    return '';
  };

  const goBack = () => {
    setUsersList({ count: 0, rows: [] });
    setUrl('online');
    setPage(1);
  };

  const showMore = () => {
    setPage(page + 1);
  };



  return (
    <div className="chat-list col-3">
      <li className="list-group-item search-section">
        {
          url !== 'online'
          && (
            <span className="arrow-back" onClick={goBack}><i className="fas fa-arrow-left" /></span>
          )
        }
        <input
          className="form-control input-shadow input-search"
          value={search}
          onChange={handleSearch}
          placeholder="Search user"
          style={{ paddingLeft: '1.5em' }}
        />
        {
          url === 'online'
            ? (
              <button type="button" className="btn btn-link" onClick={newConversation}>
                <i className="fas fa-plus" />
                {' New conversation'}
              </button>
            )
            : (
              <Typeahead
                labelKey="name"
                url={`${process.env.REACT_APP_API_URL}sections`}
                multiple={false}
                minLength={3}
                onChange={(option) => handleChangeSectionFilter(option[0]?.id_section)}
                placeholder="Filter by section..."
                itemChildren={(option) => (
                  <span>{`${option.id_section} ${option.name}`}</span>
                )}
                inputProps={{ required: 'required' }}
              />
            )
        }
      </li>
      <div className="users-list">
        <ul className="list-group" style={{ paddingBottom: '50px' }}>
          {
            usersList.rows.map((user) => (
              url === 'online'
                ? (
                  <li className={`list-group-item ${getActiveChat(user.to_user)}`} key={user.to_user.id_user} onClick={() => handleSelectChat(user.to_user)}>
                    <ImageUser urlImage={user.to_user.image_url} userName={`${user.to_user.name} ${user.to_user.last_name}`} size={37} />
                    {
                      user.is_online
                      && <span className="online online-success" />
                    }
                    <span>{`${user.to_user.name} ${user.to_user.last_name}`}</span>
                    {
                      user.total > 0
                      && <span className="badge badge-primary counter">{user.total}</span>
                    }
                  </li>
                )
                : (
                  <li className={`list-group-item ${getActiveChat(user)}`} key={user.id_user} onClick={() => handleSelectChat(user)}>
                    <ImageUser urlImage={user.image_url} userName={`${user.name} ${user.last_name}`} size={37} />
                    <span>{`${user.name} ${user.last_name}`}</span>
                  </li>
                )
            ))
          }
          {
            (Math.ceil(usersList.count / limit) > page)
            && (
              <li className="list-group-item" onClick={showMore}>
                <span>Show more</span>
              </li>
            )
          }
        </ul>
      </div>
    </div>
  );
};

ChatList.propTypes = {
  showLoader: PropTypes.func,
  update: PropTypes.bool,
};

ChatList.defaultProps = {
  showLoader: (f) => f,
  update: false,
};

export default ChatList;
