import React from 'react';

export const socket = null;

export const socketMessages = [];

export const SocketContext = React.createContext({
  socket,
  socketMessages,
  send: (f) => f,
  openSocket: (f) => f,
  updateNewMessages: (f) => f,
});
