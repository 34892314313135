/* eslint-disable no-lonely-if */
/* eslint-disable no-else-return */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable arrow-parens */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory, useLocation, Link } from 'react-router-dom';
import jwt from 'jwt-decode';
// import DateTime from 'react-datetime';
import moment from 'moment-timezone';
import showdown from 'showdown';
import Header from '../../components/header/header';
import {
  fetchData, CreateAuthRequest, priority, difficulty,
} from '../../API/constants';
import Files from '../../components/Files/files';
import DropDown from '../../components/dropdown/dropdown';
import UsersList from '../../components/ShowUsers/usersList';
import TodoList from '../../components/TodoList/todoListWrapper';
import TaskLogs from './taskLogs';
import StaticMap from '../../components/azurePlacesWithMap/staticMap';
import ModalConfirm from '../../components/ModalConfirm/modalConfirm';
import ModalConfirmDeleteTask from './modalDeleteTask';
import SubtasksList from './subtasksList';
import { StatusesContext } from '../../context/status/statusesContext';
import AccepteDeclineTask from './AcceptDecline/AcceptDecline';
import './tasks.scss';

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_MODAL_DELETE_TASK':
      return { ...state, modalDeleteTask: action.payload };
    case 'SET_MODAL_CHANGE_STATUS':
      return { ...state, modalChangeStatus: action.payload };
    case 'SET_USER_PERMISSIONS':
      return { ...state, userPermissions: action.payload };
    case 'SET_UPDATE_LIST':
      return { ...state, updateList: Math.random() };
    default:
      return { ...state };
  }
};

const TaskDetails = ({ showLoader, NotificationManager }) => {
  const { idTask, idSub } = useParams();
  const history = useHistory();
  const location = useLocation();
  const { statuses, updateStatuses } = React.useContext(StatusesContext);
  const initialState = {
    task: {},
    statusList: [],
    modalChangeStatus: { show: false, message: '', id: 0 },
    modalDeleteTask: { show: false },
    userPermissions: { user_type: 0 },
    updateList: 0,
  };
  const converter = new showdown.Converter({ emoji: true });
  const [task, setTask] = React.useState({});
  // const [statuses, updateStatuses] = React.useState([]);
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { modalDeleteTask, userPermissions, modalChangeStatus, updateList } = state;
  const isSubtask = location.pathname.includes('subtask');

  const getStatusList = React.useCallback(async () => {
    showLoader(true);
    const request = await CreateAuthRequest('GET', null, true);
    fetchData('statuses', request)
      .then(response => {
        updateStatuses(response.rows.sort((a, z) => a.order - z.order));
      })
      .finally(() => showLoader(false));
  }, []);

  const getTask = React.useCallback(async () => {
    showLoader(true);
    const idTaskToSearch = isSubtask ? idSub : idTask;
    const request = await CreateAuthRequest('GET', null, true);

    fetchData(`tasks/${idTaskToSearch}`, request)
      .then(response => {
        setTask(response);
      })
      .catch()
      .finally(() => showLoader(false));
  }, [idTask, idSub]);

  React.useEffect(() => {
    getTask();
  }, [getTask, idTask, idSub]);

  React.useEffect(() => {
    if (statuses.length === 0) {
      getStatusList();
    }
  }, []);

  const getUserPermissions = React.useCallback(async () => {
    showLoader(true);
    const user = jwt(sessionStorage.getItem('token'));

    const findUser = task.users.find(u => u.id_user === user.id_user);
    let accepted = false;
    if (findUser) {
      accepted = findUser.task_user.accepted || findUser.task_user.deny_reason;
    }

    if (user.user_type === 1) {
      dispatch({ type: 'SET_USER_PERMISSIONS', payload: { user_type: user.user_type, accepted } });
      showLoader(false);
      return;
    }
    const request = await CreateAuthRequest('GET', null, true);
    fetchData(`sections/${task.id_section}/users/${user.id_user}`, request)
      .then(response => {
        dispatch({ type: 'SET_USER_PERMISSIONS', payload: { ...response, accepted } });
      })
      .finally(() => showLoader(false));
  }, [task.id_section]);

  React.useEffect(() => {
    if (task.id_section) {
      getUserPermissions();
    }
  }, [task.id_section]);

  // const valid = (current) => {
  //   const day = moment().add(-1, 'days');
  //   return current.isAfter(day);
  // };

  const handlePriorityCheck = async (obj) => {
    showLoader(true);
    const request = await CreateAuthRequest('PUT', { priority: obj.id }, true);

    fetchData(`tasks/${idSub ? idSub : idTask}`, request)
      .then(response => {
        setTask(response);
        dispatch({ type: 'SET_UPDATE_LIST' });
      })
      .finally(() => showLoader(false));
  };

  const handleDifficultyCheck = async (obj) => {
    showLoader(true);
    const request = await CreateAuthRequest('PUT', { difficulty: obj.id }, true);

    fetchData(`tasks/${idSub ? idSub : idTask}`, request)
      .then(response => {
        setTask(response);
        dispatch({ type: 'SET_UPDATE_LIST' });
      })
      .finally(() => showLoader(false));
  };

  const handleStatusChange = async (e) => {
    const { target } = e;
    if (task.id_status === 2) {
      if (userPermissions.user_type === 1 || userPermissions === 2) {
        dispatch({ type: 'SET_MODAL_CHANGE_STATUS', payload: { id: target.value, show: true, message: target.options[target.selectedIndex].text } });
      } else {
        NotificationManager.warning("You don't have permission to change closed status.", 'Warning!', 5000);
      }
    } else if (target.value === '2') {
      if (userPermissions.user_type === 1 || userPermissions === 2) {
        dispatch({ type: 'SET_MODAL_CHANGE_STATUS', payload: { id: 2, show: true, message: target.options[target.selectedIndex].text } });
      } else {
        NotificationManager.warning("You don't have permission to close this task.", 'Warning!', 5000);
      }
    } else {
      showLoader(true);
      const request = await CreateAuthRequest('PUT', { id_status: target.value }, true);

      fetchData(`tasks/${idTask}/status`, request)
        .then(response => {
          setTask(response);
          dispatch({ type: 'SET_UPDATE_LIST' });
          NotificationManager.success('Task status updated.', 'Success!', 5000);
        })
        .finally(() => showLoader(false));
    }
  };

  const handleFileUpload = async (file) => {
    showLoader(true);
    const request = await CreateAuthRequest('POST', null, false);
    const body = new FormData();
    body.append('file', file);
    request.body = body;
    const idToSend = idSub ? idSub : idTask;
    fetchData(`tasks/${idToSend}/files`, request)
      .then(response => {
        setTask({ ...task, task_files: [...task.task_files, response] });
        dispatch({ type: 'SET_UPDATE_LIST' });
        NotificationManager.success('File has been uploaded successfully.', 'Success!', 5000);
      })
      .finally(() => showLoader(false));
  };

  const handleFileDelete = async (file) => {
    const request = await CreateAuthRequest('DELETE', null, true);
    const idToSend = idSub ? idSub : idTask;
    fetchData(`tasks/${idToSend}/files/${file.id_task_file}`, request)
      .then(() => {
        NotificationManager.success('File deleted successfully.', 'Success!', 500);
        const obj = { ...task, task_files: task.task_files.filter(f => f.id_task_file !== file.id_task_file) };
        console.log('delete file', obj);
        setTask(obj);
        dispatch({ type: 'SET_UPDATE_LIST' });
      })
      .catch(() => {
        NotificationManager.error('An error ocurred. Please try again later.', 'Error!', 500);
      });
  };

  const showDescription = () => {
    if (task.description && task.description !== '') {
      return { __html: converter.makeHtml(task.description) };
    }
    return { __html: '<p>Not description yet</p>' };
  };

  const handleModalDeleteShow = () => {
    dispatch({ type: 'SET_MODAL_DELETE_TASK', payload: { ...modalDeleteTask, show: !modalDeleteTask.show } });
  };

  const deleteTask = async (disabledReason) => {
    showLoader(true);
    const request = await CreateAuthRequest('PUT', { disabledReason, disabled: true }, true);

    fetchData(`tasks/${idTask}`, request)
      .then(() => {
        NotificationManager.success('Task has been deleted.', 'Success!', 5000);
        history.push('/pannel/tasks');
      })
      .finally(() => showLoader(false));
  };

  const handleModalChangeStatusShow = () => {
    dispatch({ type: 'SET_MODAL_CHANGE_STATUS', payload: { message: '', show: false } });
  };

  const handleUpdateTaskStatus = async () => {
    if (userPermissions.user_type === 1 || userPermissions === 2) {
      showLoader(true);
      const request = await CreateAuthRequest('PUT', { id_status: modalChangeStatus.id }, true);
      fetchData(`tasks/${idSub ? idSub : idTask}`, request)
        .then(response => {
          setTask(response);
          dispatch({ type: 'SET_MODAL_CHANGE_STATUS', payload: { message: '', show: false, id: 0 } });
          dispatch({ type: 'SET_UPDATE_LIST' });
          NotificationManager.success('Task status updated.', 'Success!', 5000);
        })
        .finally(() => showLoader(false));
    }
  };

  // const handleDueDateChange = async ({ target }) => {
  //   showLoader(true);
  //   const request = await CreateAuthRequest('PUT', { id_status: target.value }, true);

  //   fetchData(`tasks/${idTask}`, request)
  //     .then(response => {
  //       setTask(response);
  //     })
  //     .finally(() => showLoader(false));
  // };

  const handleGoToEdit = () => {
    if (isSubtask) {
      history.push(`/pannel/tasks/${idTask}/subtask/${idSub}/edit`);
    } else {
      history.push(`/pannel/tasks/edit/${idTask}`);
    }
  };

  const handleAcceptDeclineTask = async (type, reason) => {
    showLoader(true);
    const body = { id_task: idSub ? idSub : idTask, accept_status: reason ? false : true };
    if (reason) {
      body.deny_reason = reason;
    }
    const request = await CreateAuthRequest('POST', body, true);
    fetchData('me/tasks/assignments', request)
      .then(response => {
        setTask({
          ...task,
          users: task.users.map(u => ((u.id_user === userPermissions.id_user)
            ? { ...u, task_user: { ...u.task_user, ...response } }
            : { ...u })),
        });
        dispatch({ type: 'SET_UPDATE_LIST' });
        dispatch({ type: 'SET_USER_PERMISSIONS', payload: { ...userPermissions, accepted: true } });
      }).finally(() => { showLoader(false); });
  };

  return (
    <div className="task-details">
      <Header
        title="Task details"
        right={() => {
          // if (userPermissions.accepted === false && task.is_supervisor !== 1) {
          //   return <AccepteDeclineTask handleOnSubmit={handleAcceptDeclineTask} />;
          // }
          if (task.users) {
            const findUser = task.users.find(u => u.id_user === userPermissions.id_user);
            if (findUser) {
              if (findUser.task_user.accept_status === null && task.is_supervisor !== 1) {
                return <AccepteDeclineTask handleOnSubmit={handleAcceptDeclineTask} />;
              } else {
                if (findUser.task_user.accept_status) {
                  return <div className="col-md-3 col-12 tar"><span className="badge badge-success">You have been accepted this task.</span></div>;
                } else {
                  if (findUser.task_user.accept_status !== null) {
                    return <div className="col-md-3 col-12 tar"><span className="badge badge-danger">You have been declined this task.</span></div>;
                  }
                  return null;
                }
              }
            }
            return null;
          }
          return null;
        }}
      />
      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col">
              <h4 className="mg-0">{task.title}</h4>
            </div>
            {
              (userPermissions.user_type === 1 || userPermissions.user_type === 2)
              && (
                <div className="col-3 tar btn-container">
                  <button type="button" className="btn btn-sm btn-danger btn-radius" onClick={handleModalDeleteShow}>
                    <i className="fas fa-trash" />
                    {' Delete'}
                  </button>
                  <button type="button" className="btn btn-sm btn-primary btn-radius" onClick={handleGoToEdit}>
                    <i className="fas fa-edit" />
                    {' Edit'}
                  </button>
                </div>
              )
            }

          </div>
        </div>
        <div className="card-body">
          <div className="row wrap">
            <div className="col-8 detail">
              <div className="row">
                <div className="col-12 col-md-4">
                  <label>Status</label>
                  <select className="form-control" value={task.id_status} onChange={handleStatusChange}>
                    {
                      (userPermissions.user_type === 1 || userPermissions.user_type === 2)
                        ? statuses.map(status => (
                          <option
                            key={status.id_status}
                            value={status.id_status}
                            style={{ color: '#fff', backgroundColor: `#${status.color}` }}
                          >
                            {status.name}
                          </option>
                        ))
                        : statuses.slice(0, 4).map(status => (
                          <option
                            key={status.id_status}
                            value={status.id_status}
                            style={{ color: '#fff', backgroundColor: `#${status.color}` }}
                          >
                            {status.name}
                          </option>
                        ))
                    }
                  </select>
                </div>
                <div className="col-12 col-md-4">
                  <label>Due date</label>
                  {
                    task.deadline_date
                      ? <p>{moment(task.deadline_date).tz('Atlantic/Bermuda').format('DD/MMM/YYYY h:mm a')}</p>
                      : <p>Not available</p>
                  }
                  {/* <DateTime
                    closeOnSelect
                    dateFormat="DD/MMM/YYYY"
                    timeFormat
                    isValidDate={valid}
                    onChange={(dateMoment) => handleDueDateChange({
                      target: {
                        name: 'deadline_date',
                        value: moment(dateMoment).toISOString(),
                      },
                    })}
                    value={task.deadline_date && moment(task.deadline_date).tz('Atlantic/Bermuda')}
                    inputProps={{ placeholder: 'Choose due date' }}
                  /> */}
                </div>
                <div className="col-12 col-md-4">
                  <label>Priority</label>
                  <DropDown
                    options={priority}
                    defaultSelected={task.priority}
                    onSelectOption={handlePriorityCheck}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <label>Difficulty</label>
                  <DropDown
                    options={difficulty}
                    defaultSelected={task.difficulty}
                    onSelectOption={handleDifficultyCheck}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <label>Section</label>
                  {
                    task.section
                      ? <p>{task.section.name}</p>
                      : (
                        <p>
                          <i className="fas fa-ban" />
                          Not Assigned
                        </p>
                      )
                  }
                </div>
                <div className="col-12 col-md-4">
                  <label>Supervisor</label>
                  {
                    task.supervisor_user
                      ? <p>{`${task.supervisor_user.name} ${task.supervisor_user.last_name}`}</p>
                      : (
                        <p>
                          Not Assigned
                        </p>
                      )
                  }
                </div>
                {
                  (isSubtask && task.parent_task)
                  && (
                    <div className="col-12">
                      <label>Parent taks</label>
                      <p><Link to={`/pannel/tasks/detail/${task.parent_task.id_task}`}>{task.parent_task.title}</Link></p>
                    </div>
                  )
                }
                <div className="col-12">
                  <label>Description</label>
                  <div className="mg-top" dangerouslySetInnerHTML={showDescription()} />
                </div>
                <div className="col-12">
                  <label>Location</label>
                  {
                    task.location
                      ? (
                        <>
                          <p>{task.location.address}</p>
                          <StaticMap
                            zoom={16}
                            initialPos={[task.location.latitude, task.location.longitude]}
                            positionProp={[task.location.latitude, task.location.longitude]}
                          />
                        </>
                      )
                      : <p>Not available</p>
                  }
                </div>
              </div>
              <UsersList
                // keylabel="user"
                label="Assigned to"
                users={task.users}
                zeroResutls="Not assigned yet."
              />
              <TodoList
                idTask={idSub ? idSub : idTask}
                lists={{ count: 0, rows: [] }}
                update={updateList}
              />

              {
                !isSubtask
                && <SubtasksList idTask={idTask} />
              }

              <Files
                label="Files"
                labelKey="original_filename"
                idKey="id_task_file"
                list={task.task_files}
                onFileDelete={handleFileDelete}
                onFileUpload={handleFileUpload}
                NotificationManager={NotificationManager}
              />
            </div>

            <TaskLogs
              idTask={idSub ? idSub : idTask}
              showLoader={showLoader}
              statusList={statuses}
              task={task}
            />

          </div>
        </div>
      </div>
      <ModalConfirm
        type="change"
        show={modalChangeStatus.show}
        message={`status to ${modalChangeStatus.message}`}
        onConfirm={handleUpdateTaskStatus}
        onHide={handleModalChangeStatusShow}
      />
      <ModalConfirmDeleteTask
        show={modalDeleteTask.show}
        onConfirm={deleteTask}
        onHide={handleModalDeleteShow}
      />
    </div>
  );
};

TaskDetails.propTypes = {
  NotificationManager: PropTypes.object,
  showLoader: PropTypes.func,
};

TaskDetails.defaultProps = {
  NotificationManager: f => f,
  showLoader: f => f,
};

export default TaskDetails;
