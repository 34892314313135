/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable arrow-parens */
import React from 'react';
import PropTypes from 'prop-types';
import InputFile from './inputFile';
import FilesList from './filesList';

const Files = ({
  label, list, onFileDelete, labelKey, idKey, onFileUpload, NotificationManager,
}) => {
  const handleDeleteFile = (objFile) => {
    onFileDelete(objFile);
  };

  const handleFileUpload = (objFile) => {
    onFileUpload(objFile);
  };

  return (
    <div>
      <InputFile
        label={label}
        handleFileUpload={handleFileUpload}
        NotificationManager={NotificationManager}
      />
      <FilesList
        labelKey={labelKey}
        idKey={idKey}
        list={list}
        handleDeleteFile={handleDeleteFile}
      />
    </div>
  );
};

Files.propTypes = {
  labelKey: PropTypes.string.isRequired,
  idKey: PropTypes.string.isRequired,
  label: PropTypes.string,
  list: PropTypes.array,
  onFileDelete: PropTypes.func,
  onFileUpload: PropTypes.func,
  NotificationManager: PropTypes.object,
};

Files.defaultProps = {
  label: null,
  list: [
    // { id_task_file: 1, original_filename: 'my_file.pdf' },
    // { id_task_file: 2, original_filename: 'my_file.doc' },
    // { id_task_file: 3, original_filename: 'my_file.docx' },
    // { id_task_file: 4, original_filename: 'my_file.xls' },
    // { id_task_file: 5, original_filename: 'my_file.xlsx' },
    // { id_task_file: 6, original_filename: 'my_file.png' },
    // { id_task_file: 7, original_filename: 'my_file.jpg' },
    // { id_task_file: 8, original_filename: 'my_file.jpeg' },
    // { id_task_file: 9, original_filename: 'my_file.svg' },
    // { id_task_file: 10, original_filename: 'my_file.gif' },
    // { id_task_file: 11, original_filename: 'my_file.csv' },
  ],
  onFileDelete: f => f,
  onFileUpload: f => f,
  NotificationManager: null,
};

export default Files;
