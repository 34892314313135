/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import moment from 'moment-timezone';
import UsersList from '../../components/ShowUsers/usersList';
import { priority } from '../../API/constants';

const TaskCard = (card) => {
  const getPriority = () => {
    const priorityCard = priority.find((p) => p.id === card.priority);
    return (
      <>
        <i className="fas fa-flag" style={{ color: priorityCard.color }} />
        {priorityCard.name}
      </>
    );
  };

  const calculateProgress = () => {
    if (card.task_lists.length === 0) {
      return null;
    }
    const { done, in_progress } = card.task_lists.reduce((accumulator, current) => ({
      done: accumulator.done + current.done,
      in_progress: accumulator.in_progress + current.in_progress,
    }), { done: 0, in_progress: 0 });
    const total = done + in_progress;
    const porcentaje = (done * 100) / total;
    const elm = (
      <div className="mg-top">
        <label className="data-card">Progress</label>
        <div title={`${porcentaje}%`} className="progress">
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${porcentaje}%` }}
            aria-valuenow={porcentaje}
            aria-valuemin="0"
            aria-valuemax="100"
          />
        </div>
      </div>
    );
    return elm;
  };

  return (
    <div className="card custom-card">
      <div className="card-header">
        <label>{card.title}</label>
      </div>
      <div className="card-body">
        <div className="">
          <label className="title-card">Section</label>
          <label className="data-card">{card.section.name}</label>
        </div>
        <div className="row mg-top">
          <div className="col-6">
            <label className="title-card">Due date</label>
            <label className="data-card">{card.deadline_date ? moment(card.deadline_date).tz('Atlantic/Bermuda').format('MMM DD') : 'N/A'}</label>
          </div>
          <div className="col-6">
            <label className="title-card">Priority</label>
            <label className="data-card">{getPriority()}</label>
          </div>
        </div>
        {
          calculateProgress()
        }
        <div className="mg-top">
          <UsersList
            users={card.users}
            zeroResutls=""
            size={25}
            useDiv={false}
            maxUsersToShow={5}
          />
        </div>
      </div>
    </div>
  );
};

export default TaskCard;
