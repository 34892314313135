/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import Header from '../../components/header/header';
import Typeahead from '../../components/CustomizedTypeahead/typeahead';
import { CreateAuthRequest, fetchData } from '../../API/constants';

const maxDescriptionLength = 65000;

const wikiTypes = [
  '',
  'Organization charts - uploading documentation',
  'Directory of the sections',
  'The roles of the section',
  'Sections vision',
  'Union agreements (CAB)',
  'Job Descriptions',
  'Health and safety documents (Incidents & Accidents reports and protocol to follow) - Uploading to employee´s portal',
];

const NewWiki = ({ NotificationManager }) => {
  const history = useHistory();
  const [validated, setValidated] = React.useState(false);
  const [form, setForm] = React.useState({ type: '', editorMKDN: EditorState.createEmpty() });
  const [wikiInfo, setWikiInfo] = React.useState(null);
  const { idWiki } = useParams();
  const isEditing = !!idWiki;

  const getWikiInfo = React.useCallback(async () => {
    if (idWiki) {
      const request = await CreateAuthRequest('GET', null, true);
      fetchData(`sections/wikis/${idWiki}`, request)
        .then((response) => {
          const infoWiki = {
            title: response.title,
            abstract: response.abstract,
          };
          if (response.description) {
            const markdownString = response.description;
            const rawData = markdownToDraft(markdownString);
            const contentState = convertFromRaw(rawData);
            infoWiki.editorMKDN = EditorState.createWithContent(contentState);
          }
          setForm(infoWiki);
          setWikiInfo(response);
        });
    }
  }, [idWiki]);
  React.useEffect(() => { getWikiInfo(); }, [getWikiInfo]);

  const handleFormChange = ({ target }) => {
    const { name, value } = target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setValidated(true);
    if (e.target.checkValidity()) {
      const formToSend = { ...form };
      const rawContentState = convertToRaw(form.editorMKDN.getCurrentContent());
      const description = draftToMarkdown(rawContentState);
      formToSend.description = description;
      delete formToSend.editorMKDN;
      if (isEditing) {
        const request = await CreateAuthRequest('PUT', formToSend, true);
        fetchData(`sections/wikis/${idWiki}`, request)
          .then(() => {
            NotificationManager.success('Wiki has bean updated.', 'Success!', 5000);
            history.replace(`/pannel/wikis/details/${idWiki}`);
          });
      } else {
        const request = await CreateAuthRequest('POST', formToSend, true);
        fetchData('sections/wikis', request)
          .then((response) => {
            history.replace(`/pannel/wikis/details/${response.id_wiki}`);
            NotificationManager.success('Wiki has bean created.', 'Success!', 5000);
          })
          .catch((error) => {
            try {
              error.then((response) => {
                NotificationManager.error(response.message, 'Error!', 5000);
              });
            } catch (error1) {
              NotificationManager.error(error1, 'Error!', 5000);
            }
          });
      }
    }
  };

  return (
    <div className="nkdx">
      <Header
        title={isEditing ? 'Edit Wiki' : 'Create Wiki'}
      />
      <div className="card">
        <div className="card-body">
          <form className={`form needs-validation ${validated ? ' was-validated' : ''}`} onSubmit={handleSubmit} noValidate>
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label>Section</label>
                  {
                    wikiInfo
                      ? <p>{wikiInfo.section.name}</p>
                      : (
                        <Typeahead
                          labelKey="name"
                          url={`${process.env.REACT_APP_API_URL}sections`}
                          multiple={false}
                          minLength={3}
                          onChange={(option) => handleFormChange({ target: { name: 'id_section', value: option[0]?.id_section } })}
                          placeholder="Choose section..."
                          itemChildren={(option) => (
                            <span>{`${option.id_section} ${option.name}`}</span>
                          )}
                          inputProps={{ required: 'required' }}
                        />
                      )
                  }
                </div>
              </div>
              <div className="col-md-8 col-12">
                <div className="form-group">
                  <label>Title</label>
                  <input className="form-control input-shadow" placeholder="Type wiki title" name="title" value={form.title} onChange={handleFormChange} required />
                </div>
              </div>
              <div className="col-md-12 col-12">
                <div className="form-group">
                  <label>Abstract</label>
                  <textarea rows="5" className="form-control input-shadow" placeholder="Type contact person name" name="abstract" value={form.abstract} onChange={handleFormChange} />
                </div>
              </div>
              <div className="col-md-12 col-12">
                <div className="form-group">
                  <label>Description</label>
                  <Editor
                    handleBeforeInput={(val) => {
                      const textLength = form.editorMKDN.getCurrentContent().getPlainText().length;
                      if (val && textLength >= maxDescriptionLength) {
                        return 'handled';
                      }
                      return 'not-handled';
                    }}
                    handlePastedText={(val) => {
                      const textLength = form.editorMKDN.getCurrentContent().getPlainText().length;
                      return ((val.length + textLength) >= maxDescriptionLength);
                    }}
                    toolbar={
                      {
                        options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'emoji', 'history'],
                        inline: {
                          inDropdown: false,
                          className: undefined,
                          component: undefined,
                          dropdownClassName: undefined,
                          options: ['bold', 'italic', 'underline', 'strikethrough'],
                        },
                        textAlign: { inDropdown: true },
                        link: { inDropdown: true },
                      }
                    }

                    editorState={form.editorMKDN}
                    wrapperClassName="border input-shadow"
                    onEditorStateChange={(value) => handleFormChange({ target: { name: 'editorMKDN', value } })}
                  />
                </div>
              </div>
              <div className="col-12 col-md-5">
                <label>Type</label>
                {
                  isEditing
                    ? <p>{wikiInfo && wikiTypes[wikiInfo.type]}</p>
                    : (
                      <select className="form-control input-shadow" name="type" value={form.type} onChange={handleFormChange} required>
                        <option value="">Choose wiki type</option>
                        <option value={1}>Organization charts - uploading documentation</option>
                        <option value={2}>Directory of the sections</option>
                        <option value={3}>The roles of the section</option>
                        <option value={4}>Sections vision</option>
                        <option value={5}>Union agreements (CAB)</option>
                        <option value={6}>Job Descriptions</option>
                        <option value={7}>Health and safety documents (Incidents & Accidents reports and protocol to follow) - Uploading to employee´s portal</option>
                      </select>
                    )
                }

              </div>
            </div>
            <div className="mg-top tac">
              <button type="submit" className="btn btn-primary btn-radius">{isEditing ? 'Save' : 'Create'}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

NewWiki.propTypes = {
  NotificationManager: PropTypes.object,
};

NewWiki.defaultProps = {
  NotificationManager: null,
};

export default NewWiki;
