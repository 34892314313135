/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import jwDecode from 'jwt-decode';
import {
  Switch, Redirect, Route, useLocation,
} from 'react-router-dom';
import {
  NotificationContainer, NotificationManager,
} from 'react-notifications';
import SideBar from './sidebar';
import Tasks from '../Tasks/taskList';
import TasksNew from '../Tasks/newTask';
import TaskDetail from '../Tasks/detailTask';
import UsersList from '../Users/usersList';
import UserNew from '../Users/newUser';
import UserDetails from '../Users/detailsUser';
import UserChangePassword from '../Users/changePassword';
import Sections from '../Sections/sectionsList';
import MinistryNew from '../Ministries/newMinistry';
import Ministries from '../Ministries/MinistriesList';
import MinistryDetails from '../Ministries/detailsMinistry';
import SectionNew from '../Sections/newSection';
import Jobs from '../Jobs/jobsList';
import NewJob from '../Jobs/newJob';
import JobDetails from '../Jobs/jobDetail';
import TasksHistory from '../TasksHistory/taskHistory';
import Wikis from '../Wikis/wikisList';
import NewWiki from '../Wikis/newWiki';
import WikiDetails from '../Wikis/wikiDetails';
import Calendar from '../Calendar/calendarView';
import NotificationsList from '../Notifications/NotificationsList';
import NotificationsDetails from '../Notifications/detailsNotification';
import NewNotification from '../Notifications/newNotification';
import Reports from '../Reports/reports';
import Map from '../Map/map';
import Chat from '../Chat/chatIndex';
import TopBar from './topBar';
import Profile from '../Users/profile';
import './sidebar.scss';
import { SidebarContext } from '../../context/sidebar/sidebarContext';
import Loader from '../../components/Loader/loader';
import loaderConnect from '../../components/Loader/loaderConnect';
import StatusesContextProvider from '../../context/status/statusesProvider';
import { permissionNotification, AskNotificationPermission } from '../../API/constants';
import SocketContextProvider from '../../context/socketContext/socketContextProvider';
import UserContextProvider from '../../context/userContext/userProvider';

const Container = ({ showLoader }) => {
  const { sidebar } = React.useContext(SidebarContext);
  const [containerClass, setContainerclass] = React.useState('container');
  const location = useLocation();
  const user = jwDecode(sessionStorage.token);

  React.useEffect(() => {
    if (location.pathname === '/pannel/tasks') {
      setContainerclass('container-fluid');
    } else {
      setContainerclass('container');
    }
  }, [location.pathname]);

  React.useEffect(() => {
    if (permissionNotification === 'default') {
      AskNotificationPermission();
    }
  }, []);

  const getRoutes = () => {
    const obj = [];
    if (user.user_type === 1) {
      obj.push(<Route path="/pannel/reports" exact component={() => <Reports NotificationManager={NotificationManager} showLoader={showLoader} />} />);
      obj.push(<Route path="/pannel/users/change_password/:idUser" exact component={() => <UserChangePassword NotificationManager={NotificationManager} showLoader={showLoader} />} />);
      obj.push(<Route path="/pannel/users/details/:idUser" exact component={() => <UserDetails NotificationManager={NotificationManager} showLoader={showLoader} />} />);
      obj.push(<Route path="/pannel/users/edit/:idUser" exact component={() => <UserNew NotificationManager={NotificationManager} showLoader={showLoader} />} />);
      obj.push(<Route path="/pannel/users/new" exact component={() => <UserNew NotificationManager={NotificationManager} showLoader={showLoader} />} />);
      obj.push(<Route path="/pannel/users" exact component={() => <UsersList NotificationManager={NotificationManager} showLoader={showLoader} />} />);
    }
    if (user.user_type === 1 || user.user_type === 2) {
      obj.push(<Route path="/pannel/sections/edit/:idSection" exact component={() => <SectionNew NotificationManager={NotificationManager} showLoader={showLoader} />} />);
      obj.push(<Route path="/pannel/sections/new" exact component={() => <SectionNew NotificationManager={NotificationManager} showLoader={showLoader} />} />);
      obj.push(<Route path="/pannel/sections" exact component={() => <Sections NotificationManager={NotificationManager} showLoader={showLoader} />} />);
      obj.push(<Route path="/pannel/ministries/details/:idMinistry" exact component={() => <MinistryDetails NotificationManager={NotificationManager} showLoader={showLoader} />} />);
      obj.push(<Route path="/pannel/ministries/edit/:idMinistry" exact component={() => <MinistryNew NotificationManager={NotificationManager} showLoader={showLoader} />} />);
      obj.push(<Route path="/pannel/ministries/new" exact component={() => <MinistryNew NotificationManager={NotificationManager} showLoader={showLoader} />} />);
      obj.push(<Route path="/pannel/ministries" exact component={() => <Ministries NotificationManager={NotificationManager} showLoader={showLoader} />} />);
      obj.push(<Route path="/pannel/jobs/details/:idJob" exact component={() => <JobDetails NotificationManager={NotificationManager} showLoader={showLoader} />} />);
      obj.push(<Route path="/pannel/jobs/edit/:idJob" exact component={() => <NewJob NotificationManager={NotificationManager} showLoader={showLoader} />} />);
      obj.push(<Route path="/pannel/jobs/new" exact component={() => <NewJob NotificationManager={NotificationManager} showLoader={showLoader} />} />);
      obj.push(<Route path="/pannel/jobs" exact component={() => <Jobs NotificationManager={NotificationManager} showLoader={showLoader} />} />);
      obj.push(<Route path="/pannel/wikis/details/:idWiki" exact component={() => <WikiDetails NotificationManager={NotificationManager} showLoader={showLoader} />} />);
      obj.push(<Route path="/pannel/wikis/edit/:idWiki" exact component={() => <NewWiki NotificationManager={NotificationManager} showLoader={showLoader} />} />);
      obj.push(<Route path="/pannel/wikis/new" exact component={() => <NewWiki NotificationManager={NotificationManager} showLoader={showLoader} />} />);
      obj.push(<Route path="/pannel/wikis" exact component={() => <Wikis NotificationManager={NotificationManager} showLoader={showLoader} />} />);
      obj.push(<Route path="/pannel/map" exact component={() => <Map NotificationManager={NotificationManager} showLoader={showLoader} />} />);
    }
    return obj;
  };

  return (
    <SocketContextProvider>
      <UserContextProvider>
        <div id="wrapper" className={sidebar}>
          <Loader />
          <NotificationContainer />
          <TopBar />
          <div id="page-content-wrapper" className={containerClass} style={{ marginBottom: '2em' }}>
            <SideBar />
            <div className="page-container">
              <StatusesContextProvider>
                <Switch>
                  <Redirect exact from="/pannel" to="/pannel/tasks" />
                  <Route path="/pannel/tasks/new" exact component={() => <TasksNew NotificationManager={NotificationManager} showLoader={showLoader} />} />
                  <Route path="/pannel/tasks/:id/subtask/:idSub/edit" exact component={() => <TasksNew NotificationManager={NotificationManager} showLoader={showLoader} />} />
                  <Route path="/pannel/tasks/:id/subtask" exact component={() => <TasksNew NotificationManager={NotificationManager} showLoader={showLoader} />} />
                  <Route path="/pannel/tasks/edit/:id" exact component={() => <TasksNew NotificationManager={NotificationManager} showLoader={showLoader} />} />
                  <Route path="/pannel/tasks/detail/:idTask/subtask/:idSub" exact component={() => <TaskDetail NotificationManager={NotificationManager} showLoader={showLoader} />} />
                  <Route path="/pannel/tasks/detail/:idTask" component={() => <TaskDetail NotificationManager={NotificationManager} showLoader={showLoader} />} />
                  <Route path="/pannel/tasks" exact component={() => <Tasks NotificationManager={NotificationManager} showLoader={showLoader} />} />
                  <Route path="/pannel/calendar" exact component={() => <Calendar NotificationManager={NotificationManager} showLoader={showLoader} />} />
                  <Route path="/pannel/history" exact component={() => <TasksHistory NotificationManager={NotificationManager} showLoader={showLoader} />} />
                  <Route path="/pannel/messages" exact component={() => <Chat NotificationManager={NotificationManager} showLoader={showLoader} />} />
                  <Route path="/pannel/notifications/details/:idNotification" exact component={() => <NotificationsDetails NotificationManager={NotificationManager} showLoader={showLoader} />} />
                  <Route path="/pannel/notifications/new" exact component={() => <NewNotification NotificationManager={NotificationManager} showLoader={showLoader} />} />
                  <Route path="/pannel/notifications" exact component={() => <NotificationsList NotificationManager={NotificationManager} showLoader={showLoader} />} />
                  <Route path="/pannel/users/profile/:idUser" exact component={() => <Profile NotificationManager={NotificationManager} showLoader={showLoader} />} />
                  <Route path="/pannel/users/edit/:idUser/profile" exact component={() => <UserNew NotificationManager={NotificationManager} showLoader={showLoader} />} />
                  <Route path="/pannel/users/change_password/:idUser/profile" exact component={() => <UserChangePassword NotificationManager={NotificationManager} showLoader={showLoader} />} />
                  {getRoutes()}
                </Switch>
              </StatusesContextProvider>
            </div>
          </div>
        </div>
      </UserContextProvider>
    </SocketContextProvider>
  );
};

Container.propTypes = {
  showLoader: PropTypes.func,
};

Container.defaultProps = {
  showLoader: (f) => f,
};

export default loaderConnect(Container);
