/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import { CreateAuthRequest, fetchData } from '../../API/constants';
import { ChatContext } from './chatContext/chatContext';

const FormSendMessage = ({ addNewMessage }) => {
  const { chat: { selectedChat } } = React.useContext(ChatContext);
  const [message, setMessage] = React.useState('');

  const sendMessage = async (e) => {
    e.preventDefault();
    const request = await CreateAuthRequest('POST', { id_user: selectedChat.id_user, message }, true);
    fetchData('chat/users/messages', request)
      .then((response) => {
        setMessage('');
        addNewMessage(response);
      });
  };

  const handleOnChangeMessage = ({ target }) => {
    setMessage(target.value);
  };

  return (
    <div className="new-message">
      <form onSubmit={sendMessage}>
        <div className="row">
          <div className="col-11">
            <input maxLength="65535" className="form-control input-shadow" value={message} onChange={handleOnChangeMessage} />
          </div>
          <div className="col btn-submit">
            <button type="submit" className="btn btn-primary btn-radius">
              <i className="fas fa-paper-plane" />
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

FormSendMessage.propTypes = {
  addNewMessage: PropTypes.func,
};

FormSendMessage.defaultProps = {
  addNewMessage: (f) => f,
};

export default FormSendMessage;
