/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { chat, ChatContext } from './chatContext';

const ChatContextProvider = ({ children }) => {
  const [state, setChatContext] = React.useState(chat);

  const updateChat = (obj) => {
    setChatContext(obj);
  };
  return (
    <ChatContext.Provider value={{ chat: state, updateChat }}>
      {children}
    </ChatContext.Provider>
  );
};

export default ChatContextProvider;
