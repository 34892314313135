
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Header from '../../components/header/header';
import AzurePlacesWithMap from '../../components/azurePlacesWithMap/azurePlacesWithMap';
import { CreateAuthRequest, fetchData } from '../../API/constants';

const NewMnistry = ({ NotificationManager }) => {
  const history = useHistory();
  const [ministryInfo, setMinistryInfo] = React.useState({});
  const [form, setForm] = React.useState({});
  const [validated, setValidated] = React.useState(false);
  const { idMinistry } = useParams();
  const isEditing = !!idMinistry;

  const getMinistryInfo = React.useCallback(async () => {
    if (idMinistry) {
      const request = await CreateAuthRequest('GET', null, true);
      fetchData(`ministries/${idMinistry}`, request)
        .then((response) => {
          setMinistryInfo(response);
          setForm({
            name: response.name,
            contact_person: response.contact_person,
            phone_number: response.phone_number,
            email: response.email,
            description: response.description,
          });
        });
    }
  }, [idMinistry]);
  React.useEffect(() => { getMinistryInfo(); }, [getMinistryInfo]);

  const handleFormChange = ({ target }) => {
    const { name, value } = target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setValidated(true);
    if (e.target.checkValidity()) {
      const formTosend = { ...form };

      if (form.location) {
        formTosend.latitude = form.location.position.lat;
        formTosend.longitude = form.location.position.lon;
        if (form.location.poi) {
          formTosend.address = form.location.poi.name;
        } else {
          formTosend.address = form.location.address.freeformAddress;
        }
        delete formTosend.location;
      }

      if (isEditing) {
        const request = await CreateAuthRequest('PUT', formTosend, true);
        fetchData(`ministries/${idMinistry}`, request)
          .then((response) => {
            NotificationManager.success('Ministry has bean updated.', 'Success!', 5000);
            setForm({
              name: response.name,
              contact_person: response.contact_person,
              phone_number: response.phone_number,
            });
            history.replace(`/pannel/ministries/details/${idMinistry}`);
          });
      } else {
        const request = await CreateAuthRequest('POST', formTosend, true);
        fetchData('ministries', request)
          .then((response) => {
            history.replace(`/pannel/ministries/details/${response.id_ministry}`);
            NotificationManager.success('Ministry has bean created.', 'Success!', 5000);
          })
          .catch((error) => {
            try {
              error.then((response) => {
                NotificationManager.error(response.message, 'Error!', 5000);
              });
            } catch (error1) {
              NotificationManager.error(error1, 'Error!', 5000);
            }
          });
      }
    }
  };

  const getLocationValue = () => {
    if (form.location) {
      return form.location;
    }
    if (ministryInfo.address) {
      return {
        latitude: parseFloat(ministryInfo.latitude),
        longitude: parseFloat(ministryInfo.longitude),
        address: { freeformAddress: ministryInfo.address },
      };
    }
    return null;
  };

  return (
    <div className="nkdx">
      <Header
        title={isEditing ? 'Edit Ministry' : 'Create Ministry'}
      />
      <div className="card">
        <div className="card-body">
          <form className={`form needs-validation ${validated ? ' was-validated' : ''}`} onSubmit={handleSubmit} noValidate>
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="form-group">
                  <label>Name</label>
                  <input className="form-control" placeholder="Type ministry name" name="name" value={form.name} onChange={handleFormChange} required />
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="form-group">
                  <label>Contact person</label>
                  <input className="form-control" placeholder="Type contact person name" name="contact_person" value={form.contact_person} onChange={handleFormChange} />
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="form-group">
                  <label>Phone number</label>
                  <input className="form-control" placeholder="type phone number" name="phone_number" value={form.phone_number} onChange={handleFormChange} />
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="form-group">
                  <label>Email</label>
                  <input type="email" className="form-control" placeholder="type contact email" name="email" value={form.email} onChange={handleFormChange} />
                </div>
              </div>
              <div className="col-md-12 col-12">
                <div className="form-group">
                  <label>Description</label>
                  <textarea className="form-control" placeholder="type description" name="description" value={form.description} onChange={handleFormChange} />
                </div>
              </div>
              <div className="col-md-12 col-12">
                <div className="form-group">
                  <AzurePlacesWithMap
                    name="location"
                    label="Location"
                    placeholder="type address to search..."
                    icon={faPlus}
                    value={getLocationValue()}
                    handleInputChange={handleFormChange}
                  />
                </div>

              </div>
            </div>
            <div className="mg-top tac">
              <button type="submit" className="btn btn-primary btn-radius">{isEditing ? 'Save' : 'Create'}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

NewMnistry.propTypes = {
  NotificationManager: PropTypes.object,
};

NewMnistry.defaultProps = {
  NotificationManager: null,
};

export default NewMnistry;
