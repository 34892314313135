/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { sidebar, SidebarContext } from './sidebarContext';

const SidebarContextProvider = ({ children }) => {
  const [state, setSidebarContext] = React.useState(sidebar);

  return (
    <SidebarContext.Provider value={{ sidebar: state, updateSidebar: setSidebarContext }}>
      {children}
    </SidebarContext.Provider>
  );
};

export default SidebarContextProvider;
