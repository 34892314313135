/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable arrow-parens */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';

const ModalDecline = ({ show, onHide, onConfirm }) => {
  const [reason, setReason] = React.useState('');
  const [validated, setValidated] = React.useState(false);

  const handleInputChange = ({ target }) => {
    setReason(target.value);
  };

  const confirmDeletion = (e) => {
    e.preventDefault();
    setValidated(true);
    if (e.target.checkValidity()) {
      onConfirm(reason);
      setReason('');
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Confirm decline task</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h3>Are you sure you want to decline this task?</h3>
        <form className={`form needs-validation ${validated ? ' was-validated' : ''}`} onSubmit={confirmDeletion} noValidate>
          <div className="form-group">
            <label>Please, write decline reason.</label>
            <textarea className="form-control" value={reason} onChange={handleInputChange} required />
          </div>
          <Modal.Footer>
            <button type="button" className="btn btn-default btn-radius" onClick={() => onHide()}>Close</button>
            <button type="submit" className="btn btn-danger btn-radius">Confirm decline</button>
          </Modal.Footer>
        </form>
      </Modal.Body>
    </Modal>
  );
};

ModalDecline.propTypes = {
  show: PropTypes.bool,
  onConfirm: PropTypes.func,
  onHide: PropTypes.func,
};

ModalDecline.defaultProps = {
  show: false,
  onConfirm: f => f,
  onHide: f => f,
};

export default ModalDecline;
