/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable arrow-parens */
import React from 'react';
import PropTypes from 'prop-types';
import { ImageUser } from '../ShowUsers/usersList';
import InputDebounce from '../InputDebounce/inputDebounce';
import { CreateAuthRequest } from '../../API/constants';
import './selectableList.scss';

// region SELECTABLE LIST
const initialState = {
  search: '',
  options: {
    count: 0,
    rows: [],
  },
  selectedOptions: [],
  page: 1,
};

const Actions = {
  SET_SEARCH: 'SET_SEARCH',
  SET_OPTIONS: 'SET_OPTIONS',
  SET_SELECTED_OPTIONS: 'SET_SELECTED_OPTIONS',
  SET_PAGE: 'SET_PAGE',
  RESET: 'RESET',
};

const reducer = (state, action) => {
  switch (action.type) {
    case Actions.SET_SEARCH:
      return { ...state, search: action.payload, page: 1 };
    case Actions.SET_OPTIONS:
      return { ...state, options: action.payload };
    case Actions.SET_SELECTED_OPTIONS:
      return { ...state, selectedOptions: action.payload };
    case Actions.SET_PAGE:
      return { ...state, page: action.payload };
    case Actions.RESET:
      return {
        ...state,
        search: '',
        options: {
          count: 0,
          rows: [],
        },
        selectedOptions: [],
        page: 1,
      };
    default:
      return { ...state };
  }
};

const SelectableList = ({
  url, label, placeholder,
  icon, onSelectItem, labelKey, idKey,
  defaultSelected, inputProps,
}) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const {
    search, page, options: { count, rows }, selectedOptions,
  } = state;

  const handleSearch = React.useCallback(async () => {
    // TODO: llamar api con url como prop
    if (url) {
      const request = await CreateAuthRequest('GET', null, true);
      fetch(`${url}?search=${search}&page=${page}`, request)
        .then(response => response.json())
        .then(response => {
          if (response.rows) {
            dispatch({ type: Actions.SET_OPTIONS, payload: response });
          }
        })
        .catch()
        .finally();
    }
  }, [search, page, url]);

  React.useEffect(() => {
    handleSearch();
  }, [search]);

  React.useEffect(() => {
    dispatch({ type: Actions.RESET });
    onSelectItem([]);
    handleSearch();
  }, [url]);

  React.useEffect(() => {
    dispatch({ type: Actions.SET_SELECTED_OPTIONS, payload: defaultSelected });
    onSelectItem(defaultSelected);
  }, [defaultSelected, url]);

  const handleInputChange = (newSearch) => {
    dispatch({ type: Actions.SET_SEARCH, payload: newSearch });
  };

  const selectedItems = (list) => {
    dispatch({ type: Actions.SET_SELECTED_OPTIONS, payload: list });
    onSelectItem(list);
  };

  const handlePageChange = () => {
    const pages = Math.ceil(count / 10);
    if (pages > page) {
      dispatch({ type: Actions.SET_PAGE, payload: page + 1 });
      handleSearch();
    }
  };

  return (
    <div className="selectable-list">
      {label && <label htmlFor>{label}</label>}
      <InputDebounce
        key={`${url}1`}
        icon={icon}
        placeholder={placeholder}
        value=""
        handleInputChange={handleInputChange}
        inputProps={inputProps}
      />
      <List
        options={rows}
        onItemClick={selectedItems}
        totalResults={count}
        selectedOptions={selectedOptions}
        handlePageChange={handlePageChange}
        idKey={idKey}
        labelKey={labelKey}
      />
    </div>
  );
};

SelectableList.propTypes = {
  url: PropTypes.string,
  labelKey: PropTypes.string.isRequired,
  idKey: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  icon: PropTypes.string,
  onSelectItem: PropTypes.func,
  defaultSelected: PropTypes.array,
  inputProps: PropTypes.object,
};

SelectableList.defaultProps = {
  inputProps: null,
  url: null,
  icon: 'fas fa-search',
  label: null,
  placeholder: 'Type to search...',
  onSelectItem: f => f,
  defaultSelected: [],
};
// endregion

// region LIST
const List = ({
  zeroResult, options, onItemClick, selectedOptions,
  handlePageChange, idKey, labelKey,
}) => {
  const divRef = React.useRef(null);
  const handleSelectOption = (option) => {
    const optionHelper = selectedOptions.find(op => op[idKey] === option[idKey]);
    let newSelectedOptions = [];
    if (optionHelper) {
      newSelectedOptions = selectedOptions.filter(op => op[idKey] !== option[idKey]);
    } else {
      newSelectedOptions = [...selectedOptions, option];
    }
    onItemClick(newSelectedOptions);
    // setSelectedOptions(newSelectedOptions);
  };

  const getIsSelected = React.useCallback((id) => {
    const isSelected = selectedOptions.find((option) => option[idKey] === id);
    if (isSelected) {
      return ' active';
    }
    return '';
  }, [selectedOptions, idKey]);

  const handleOnScroll = ({ currentTarget, target }) => {
    if (currentTarget.scrollHeight - target.scrollTop === target.clientHeight) {
      handlePageChange();
    }
  };

  return (
    <div className="card employee-list input-shadow">
      <div className="card-body">
        <ul ref={divRef} className="list-group row" onScroll={handleOnScroll}>
          {
            options.length > 0
              ? options.map((option) => (
                <li
                  key={option[idKey]}
                  onClick={() => handleSelectOption(option)}
                  className={`list-group-item col-12 col-md-6 ${getIsSelected(option[idKey])}`}
                >
                  <div className="row">
                    <div className="col-3 col-md-1 img-container">
                      <ImageUser userName={option.name} size={35} />
                    </div>
                    <div className="col">
                      <p style={{ lineHeight: '46px' }}>{labelKey(option)}</p>
                      {/* <span className="badge badge-light">Position</span> */}
                    </div>
                  </div>

                </li>
              ))
              : <div><span>{zeroResult}</span></div>
          }
        </ul>
      </div>
    </div>
  );
};

List.propTypes = {
  idKey: PropTypes.string.isRequired,
  labelKey: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onItemClick: PropTypes.func,
  zeroResult: PropTypes.string,
  selectedOptions: PropTypes.isRequired,
  handlePageChange: PropTypes.func,

};

List.defaultProps = {
  onItemClick: f => f,
  zeroResult: 'No results',
  handlePageChange: f => f,
};

// endregion

export default SelectableList;
