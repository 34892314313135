/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-else-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import jwt from 'jwt-decode';
import FileSaver from 'file-saver';
import { ChatContext } from './chatContext/chatContext';
import { CreateAuthRequest, fetchData } from '../../API/constants';
import FormSendMessage from './formSendMessage';
import ShowSelectedUser from './showSelectedUser';
import { SocketContext } from '../../context/socketContext/socketContext';

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_MESSAGES':
      return { ...state, messages: action.payload };
    case 'SET_PAGE':
      return { ...state, page: action.payload };
    case 'SET_HAS_NEXT_PAGE':
      return { ...state, hasNextPage: action.payload };
    case 'SET_IS_NEXT_PAGE_LOADING':
      return { ...state, isNextPageLoading: action.payload };
    default:
      return { ...state };
  }
};

const ChatConversation = ({ showLoader, NotificationManager, setUpdate }) => {
  const { socketMessages } = React.useContext(SocketContext);
  const { chat } = React.useContext(ChatContext);
  const { selectedChat } = chat;
  // const [messages, setMessages] = React.useState({ count: 0, rows: [] });
  const initialState = {
    messages: { count: 0, rows: [] },
    page: 1,
    hasNextPage: false,
    isNextPageLoading: false,
  };
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const {
    messages, page,
  } = state;
  const totalPages = Math.ceil(messages.count / 10);
  const [previousHeight, setPrev] = React.useState(0);

  const me = jwt(sessionStorage.token);

  const getMessages = React.useCallback(async () => {
    if (selectedChat) {
      // showLoader(true);
      setUpdate(true);
      const request = await CreateAuthRequest('GET', null, true);
      fetchData(`chat/users/${selectedChat.id_user}/messages?page=1&limit=10`, request)
        .then((response) => {
          dispatch({
            type: 'SET_MESSAGES',
            payload: {
              count: response.count,
              rows: response.rows.sort((a, z) => a.id_user_message - z.id_user_message),
            },
          });
        })
        .finally(() => {
          const node = document.getElementsByClassName('messages-section')[0];
          if (node) {
            node.scrollTop = node.scrollHeight;
            setPrev(node.scrollHeight);
          }
          showLoader(false);
          setUpdate(false);
        });
      dispatch({ type: 'SET_PAGE', payload: 1 });
    }
  }, [selectedChat]);

  React.useEffect(() => {
    getMessages();
  }, [getMessages]);

  React.useEffect(() => {
    setTimeout(() => {
      const node = document.getElementsByClassName('messages-section')[0];
      if (node) {
        node.scrollTop = node.scrollHeight;
        setPrev(node.scrollHeight);
      }
    }, 2000);
  }, []);

  const getMessagesFromSocket = React.useCallback(() => {
    if (socketMessages.length > 0 && selectedChat) {
      for (let i = 0; i < socketMessages.length; i += 1) {
        const element = socketMessages[i];
        console.log('element', element);
        if (element.action === 'message/file') {
          if (selectedChat.id_user === element.payload.id_user) {
            console.log('mismo chat');
            const mapFiles = messages.rows.map((m) => {
              if (m.id_user_message === element.payload.id_user_message) {
                console.log('encontre mensaje', { ...m, message_files: [...m.message_files, element.payload] });
                return { ...m, message_files: [...m.message_files, element.payload] };
              }
              return { ...m };
            });

            // (
            //   m.id_user_message === element.payload.id_user_message
            //     ? { ...m, message_files: [...m.message_files, element.payload] }
            //     : { ...m }
            // ));
            dispatch({
              type: 'SET_MESSAGES',
              payload: {
                count: messages.count,
                rows: mapFiles,
              },
            });
            setTimeout(() => {
              const node = document.getElementsByClassName('messages-section')[0];
              if (node) {
                node.scrollTop = node.scrollHeight;
                setPrev(node.scrollHeight);
              }
            }, 500);
          }
        }
        if (element.action === 'message') {
          if (selectedChat.id_user === element.payload.from_user.id_user) {
            dispatch({ type: 'SET_MESSAGES', payload: { count: messages.count + 1, rows: [...messages.rows, element.payload] } });
            setTimeout(() => {
              const node = document.getElementsByClassName('messages-section')[0];
              if (node) {
                node.scrollTop = node.scrollHeight;
                setPrev(node.scrollHeight);
              }
            }, 500);
          }
        }
      }
    }
  }, [socketMessages]);

  React.useEffect(() => {
    getMessagesFromSocket();
  }, [getMessagesFromSocket]);

  const isUrl = (str) => /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(str);

  const hasHTTP = (str) => {
    if (str.includes('http')) {
      return str;
    }
    return `//${str}`;
  };

  const formatMessage = (msg) => {
    const res = msg.split(' ');
    const helper = [];
    for (let i = 0; i < res.length; i += 1) {
      const element = res[i];
      if (isUrl(element)) {
        const url = hasHTTP(element);
        helper.push(<a href={url} target="blank">{element}</a>);
        helper.push(' ');
      } else {
        helper.push(`${element} `);
      }
    }
    return helper;
  };

  const getMessagesBypage = React.useCallback(async () => {
    if (page > 1) {
      const request = await CreateAuthRequest('GET', null, true);
      fetchData(`chat/users/${selectedChat.id_user}/messages?page=${page}&limit=10`, request)
        .then((response) => {
          const newListMessages = [...response.rows, ...messages.rows];
          dispatch({
            type: 'SET_MESSAGES',
            payload: {
              count: response.count,
              rows: newListMessages.sort((a, z) => a.id_user_message - z.id_user_message),
            },
          });
        })
        .finally(() => {
          if (page > 1) {
            const node = document.getElementsByClassName('messages-section')[0];
            node.scrollTop = node.scrollHeight - previousHeight;
            setPrev(node.scrollHeight);
          }
          // showLoader(false);
        });
    }
  }, [page]);

  React.useEffect(() => {
    getMessagesBypage();
  }, [getMessagesBypage]);

  const handleOnScroll = ({ currentTarget }) => {
    if (currentTarget.scrollTop === 0) {
      const newPage = page + 1;
      if (newPage <= totalPages) {
        dispatch({ type: 'SET_PAGE', payload: page + 1 });
      }
    }
  };

  const addNewMessage = (newMessage) => {
    setUpdate(true);
    dispatch({ type: 'SET_MESSAGES', payload: { count: messages.count + 1, rows: [...messages.rows, newMessage] } });
    setTimeout(() => {
      const node = document.getElementsByClassName('messages-section')[0];
      if (node) {
        node.scrollTop = node.scrollHeight;
        setPrev(node.scrollHeight);
        setUpdate(false);
      }
    }, 500);
  };

  const downloadFile = async (file) => {
    const name = file.filename;
    const request = { method: 'GET' };

    fetch(`${process.env.REACT_APP_API_URL}${file.file_url.slice(1, file.file_url.length)}`, request)
      .then(async (response) => {
        if (!response.message) {
          const downloadedFile = await response.blob();
          FileSaver.saveAs(downloadedFile, name);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const formatFile = (file) => {
    const ext = file.filename.split('.').pop().toLowerCase();
    if (ext === 'png' || ext === 'jpeg' || ext === 'svg' || ext === 'gif' || ext === 'jpg') {
      return (
        <img
          alt={file.filename}
          src={`${process.env.REACT_APP_API_URL}${file.file_url.slice(1, file.file_url.length)}`}
          style={{ width: '50%', cursor: 'pointer', display: 'block' }}
          onClick={() => downloadFile(file)}
        />
      );
    } else {
      let icon = '';
      switch (ext) {
        case 'pdf':
          icon = 'fas fa-file-pdf';
          break;
        case 'doc':
        case 'docx':
          icon = 'fas fa-file-word';
          break;
        case 'xls':
        case 'xlsx':
          icon = 'fas fa-file-excel';
          break;
        case 'ppt':
        case 'pptx':
          icon = 'fas fa-file-powerpoint';
          break;
        default:
          icon = 'fas fa-file-alt';
          break;
      }

      return (
        <div onClick={() => downloadFile(file)} style={{ cursor: 'pointer' }}>
          <i className={icon} />
          {' '}
          {file.filename}
        </div>
      );
    }
  };

  return (
    <div className="chat-conversation col">
      <ShowSelectedUser addNewMessage={addNewMessage} NotificationManager={NotificationManager} />
      <div className="messages-section" onScroll={handleOnScroll}>
        {
          messages.rows.map((message) => (
            <div
              key={message.id_user_message}
              className={`message ${me.id_user === message.id_from_user ? 'me' : 'other'}`}
            >
              <label className="time">{moment(message.createdAt).tz('Atlantic/Bermuda').format('DD/MM/YYYY hh:mm A')}</label>
              <div className="text-message shadow">
                <p className="name-user">{me.id_user === message.id_from_user ? 'Me' : `${selectedChat.name} ${selectedChat.last_name}`}</p>
                {message.message && formatMessage(message.message)}
                {
                  message.message_files.length > 0
                  && (formatFile(message.message_files[0]))
                }
              </div>
            </div>
          ))
        }
      </div>
      {
        selectedChat
        && <FormSendMessage addNewMessage={addNewMessage} />
      }
    </div>
  );
};

ChatConversation.propTypes = {
  showLoader: PropTypes.func,
  NotificationManager: PropTypes.object,
  setUpdate: PropTypes.func,
};

ChatConversation.defaultProps = {
  showLoader: (f) => f,
  NotificationManager: null,
  setUpdate: (f) => f,
};

export default ChatConversation;
