/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import DateTime from 'react-datetime';
import { baseUrl, CreateAuthRequest, fetchData } from '../../API/constants';

const acceptedFiles = ['jpg', 'png', 'jpeg', 'svg', 'gif'];

const licenseState = {
  NEW_LICENSE: 'NEW_LICENSE',
  EDITING_LICENSE: 'EDITING_LICENSE',
  DETAIL_LICENSE: 'DETAIL_LICENSE',
};

const LicenseUser = ({
  idUser, showLoader, NotificationManager, licenseInfo, update, isProfile,
}) => {
  const [licenseForm, setForm] = React.useState(() => {
    if (licenseInfo) {
      return {
        identifier: licenseInfo.identifier,
        expiration_date: licenseInfo.expiration_date,
      };
    }
    return {};
  });
  const [validated, setValidated] = React.useState(false);
  const [appState, setAppState] = React.useState(licenseState.NEW_LICENSE);

  React.useEffect(() => {
    if (licenseInfo) {
      setAppState(licenseState.DETAIL_LICENSE);
    }
  }, [licenseInfo]);

  const handlePictureChange = async (e) => {
    const { files } = e.target;
    const newFile = files[0];
    if (newFile) {
      if (acceptedFiles.includes(newFile.name.split('.').pop().toLowerCase())) {
        setForm({ ...licenseForm, image: newFile });
      } else {
        // NotificationManager.warning('This type of file is not allowed.', 'Warning!', 5000);
      }
    }
  };

  const handleFormChange = ({ target }) => {
    const { name, value } = target;
    setForm({ ...licenseForm, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setValidated(true);
    if (e.target.checkValidity()) {
      showLoader(true);
      const request = await CreateAuthRequest('POST', null, false);
      const body = new FormData();
      if (licenseForm.image) {
        body.append('image', licenseForm.image);
      }
      body.append('identifier', licenseForm.identifier);
      body.append('expiration_date', moment(licenseForm.expiration_date).toISOString());
      request.body = body;

      const url = isProfile ? 'me/licenses' : `users/${idUser}/licenses`;
      fetchData(url, request)
        .then((response) => {
          update(response);
          NotificationManager.success('License updated.', 'Success!', 5000);
          setAppState(licenseState.DETAIL_LICENSE);
        })
        .catch((error) => {
          try {
            error.then((response) => {
              NotificationManager.error(response.message, '', 5000);
            });
          } catch (error1) {
            NotificationManager.warning(error1, '', 5000);
          }
        })
        .finally(() => {
          showLoader(false);
        });
    }
  };

  const handleButtonEditCancelClick = (newState) => {
    setAppState(newState);
    console.log(newState, newState === licenseState.EDITING_LICENSE);
    if (newState === licenseState.EDITING_LICENSE) {
      setForm({
        identifier: licenseInfo.identifier,
        expiration_date: licenseInfo.expiration_date,
      });
    }
  };

  // console.log(licenseForm);
  if (appState === licenseState.DETAIL_LICENSE) {
    console.log('licenseInfo', licenseInfo);
    if (licenseInfo.image_url) {
      console.log(`url(${baseUrl}${licenseInfo.image_url.slice(1, licenseInfo.image_url.length)})`);
    }
  }

  return (
    <div className="license">
      <div style={{
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
      }}
      >
        <label style={{ fontSize: '20px', fontWeight: '700' }}>License information</label>
        {
          appState === licenseState.DETAIL_LICENSE
          && <button type="button" className="btn btn-primary btn-radius" onClick={() => handleButtonEditCancelClick(licenseState.EDITING_LICENSE)}>Edit</button>
        }
        {
          appState === licenseState.EDITING_LICENSE
          && <button type="button" className="btn btn-danger btn-radius" onClick={() => handleButtonEditCancelClick(licenseState.DETAIL_LICENSE)}>Cancel</button>
        }
      </div>
      {
        appState === licenseState.DETAIL_LICENSE
          ? (
            <div>

              <div className="row">
                <div className="col-md-4 col-12">
                  <div className="form-group">
                    <label>License picture</label>
                    {
                      licenseInfo.image_url
                        ? (
                          <div
                            className="img"
                            style={{ backgroundImage: `url('${baseUrl}${licenseInfo.image_url.slice(1, licenseInfo.image_url.length)}')` }}
                          />
                        )
                        : <div className="img" />
                    }
                  </div>
                </div>
                <div className="col-12 col-md-8">
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label>License number</label>
                        <p>{licenseInfo.identifier}</p>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label>Expiration date</label>
                        <p>{moment(licenseInfo.expiration_date).format('DD/MMM/YYYY')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
          : (
            <form className={`form needs-validation ${validated ? ' was-validated' : ''}`} onSubmit={handleSubmit} noValidate>
              <div className="row">
                <div className="col-md-4 col-12">
                  <div className="form-group">
                    <label>License picture</label>
                    <div className="img" style={{ backgroundImage: licenseForm.image && `url(${URL.createObjectURL(licenseForm.image)})` }}>
                      <label className="w-100">
                        <div className="placeholder">
                          <i className="fas fa-camera" />
                        </div>
                        <input
                          id="license-picture"
                          type="file"
                          className="hide"
                          accept="jpg, png, jpeg, svg, gif"
                          name="image"
                          // value={licenseForm.image}
                          onChange={handlePictureChange}
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-8">
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label>License number</label>
                        <input className="form-control input-shadow" placeholder="Type license number" name="identifier" value={licenseForm.identifier} onChange={handleFormChange} required />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label>Expiration date</label>
                        <DateTime
                          closeOnSelect
                          timeFormat={false}
                          className="input-shadow"
                          dateFormat="DD/MMM/YYYY"
                          value={licenseForm.expiration_date && moment(licenseForm.expiration_date).format('DD/MMM/YYYY')}
                          onChange={(dateMoment) => handleFormChange({ target: { name: 'expiration_date', value: moment(dateMoment) } })}
                          inputProps={{
                            placeholder: 'Filter by due date',
                            required: true,
                            readOnly: true,
                            style: { backgroundColor: '#fff' },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tac">
                <button type="submit" className="btn btn-primary btn-radius">Submit</button>
              </div>
            </form>
          )
      }

    </div>
  );
};
LicenseUser.propTypes = {
  idUser: PropTypes.string.isRequired,
  showLoader: PropTypes.func,
  NotificationManager: PropTypes.object,
  licenseInfo: PropTypes.object,
  update: PropTypes.func,
  isProfile: PropTypes.bool,
};
LicenseUser.defaultProps = {
  showLoader: (f) => f,
  NotificationManager: null,
  licenseInfo: null,
  update: (f) => f,
  isProfile: false,
};
export default LicenseUser;
